import React, { Component } from 'react'
import { connect } from 'react-redux';
import styles from './styles.module.css'
import _ from 'lodash';

class AccBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            speed: 100
        }
        this.canvas = React.createRef();
        this.parent = React.createRef();
    }

    componentDidMount() {
        //this.canvas.current.height = this.parent.current.clientHeight;
        //this.canvas.current.width = this.parent.current.clientWidth;
        setTimeout(() => {
            this.draw(0)
        }, 0)
    }

    componentWillReceiveProps(nextProps) {
        this.draw(nextProps.acc)
    }

    draw = (acc) => {
        this.canvas.current.height = this.parent.current.clientHeight * 0.75;
        //this.canvas.current.width = this.props.title ? this.parent.current.clientWidth * 0.85 : this.parent.current.clientWidth;

        /** @type {HTMLCanvasElement}  */
        const canvas = this.canvas.current;
        const ctx = canvas.getContext('2d');
        const cWidth = canvas.width;
        const cHeight = canvas.height;

        //画出底色块
        ctx.save();
        ctx.fillStyle = '#3E4B61'
        for (let i = 0; i < 20; i++) {
            ctx.fillRect(i * 7, cHeight - 30, 5, 20);
        }
        ctx.restore();

        //中线
        ctx.save();
        ctx.moveTo(cWidth / 2, cHeight - 35);
        ctx.strokeStyle = '#03DDFD';
        ctx.lineTo(cWidth / 2, cHeight - 5);
        ctx.stroke();
        ctx.restore();

        //画出加速度单位
        ctx.save();
        ctx.fillStyle = '#fff';
        ctx.font = '10px Microsoft yahei';
        ctx.textAlign = 'center';
        ctx.fillText('m/s²', cWidth - 15, cHeight - 40);
        ctx.restore();

        //画出正加速度值
        if (acc !== null && acc !== undefined && acc >= 0) {
            //画出加速度值
            const _acc = acc <= 10?acc:10
            ctx.save();
            ctx.fillStyle = '#fff';
            ctx.font = '20px Microsoft yahei';
            ctx.textAlign = 'center';
            ctx.textBaseLine = 'top';
            ctx.fillText("↑" + acc, cWidth - 55, cHeight - 40);
            ctx.restore();

            //加速度图形
            ctx.save();
            //创建渐变色
            ctx.translate(70,cHeight - 30);
            const basicLinear = ctx.createLinearGradient(0, 0, 80, 40);
            basicLinear.addColorStop(0, '#7FB800');
            basicLinear.addColorStop(0.5, '#FFC143');
            basicLinear.addColorStop(1, '#FD6D08');
            const dec = _acc - Math.floor(_acc);
            ctx.fillStyle = basicLinear;
            for(let i = 0;i<Math.floor(_acc);i++){
                ctx.fillRect(i * 7, 0, 5, 20);
            }

            if(dec !== 0){
                ctx.fillRect(Math.floor(_acc) * 7, 0, 5*dec, 20)
            }
            ctx.translate(-70,-cHeight + 30);
        }

        if (acc !== null && acc !== undefined && acc < 0) {
            const _acc = acc >= -10?acc:-10
            ctx.save();
            ctx.fillStyle = '#fff';
            ctx.font = '20px Microsoft yahei';
            ctx.textAlign = 'center';
            ctx.textBaseLine = 'top';
            ctx.fillText("↓" + acc, 30, cHeight - 40);
            ctx.restore();

            //加速度图形
            ctx.save();
            ctx.translate(68,cHeight - 30);
            const basicLinear = ctx.createLinearGradient(0, 0, -80, 40);
            basicLinear.addColorStop(0, '#7FB800');
            basicLinear.addColorStop(0.5, '#FFC143');
            basicLinear.addColorStop(1, '#FD6D08');

            const absAcc = Math.abs(_acc)
            const dec = absAcc - Math.floor(absAcc);
            ctx.fillStyle = basicLinear;

            for(let i = 0;i<Math.floor(absAcc);i++){
                ctx.fillRect(i * -7, 0, -5, 20);
            }

            if(dec !== 0){
                ctx.fillRect(Math.floor(absAcc) * -7, 0, -5*dec, 20)
            }
            ctx.translate(-68,-cHeight + 30);

        }


    }

    render() {
        return (
            <div className={styles.canvasWrapper} ref={this.parent}>
                {this.props.title && <div className={styles.accTitle}>
                    <span>实时</span>
                    <span>加速度</span>
                    </div>}
                <canvas className={styles.accBarCanvas} id="accCanvas" ref={this.canvas} width="140" height="100"></canvas>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { realTimeData } = state.realTime;
    const { info } = realTimeData;

    const acc = info && info.vutMoitor && info.vutMoitor.acceleration?info.vutMoitor.acceleration:0

    return{
        acc
    }
}

export default connect(mapStateToProps)(AccBar)