import React, { Component } from 'react';
import _ from 'lodash';
import styles from './styles.module.css';


export default class Gauge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 100
    }
    this.canvas = React.createRef();
    this.parent = React.createRef();
    this.deg0 = Math.PI / 9;
    this.deg1 = Math.PI * 11 / 45; //每小段
    this.radius = 60;
  }

  Dot() {
    this.x = 0;
    this.y = 0;
    this.draw = function (ctx,color) {
      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = color;
      ctx.arc(this.x, this.y, 3, 0, Math.PI * 2, false);
      ctx.fill();
      ctx.restore();
    };
  }

  text(process, ctx) {
    ctx.save();
    ctx.rotate(10 * this.deg0);
    ctx.fillStyle = '#fff';
    ctx.font = "normal bold 22px 'Microsoft yahei' ";
    ctx.textAlign = 'center';
    ctx.textBaseLine = 'top';
    ctx.fillText(process, 0, 0);
    ctx.restore();
  }

  resize = (reDraw) => {
    this.canvas.current.height = this.parent.current.clientHeight;
    this.canvas.current.width = this.props.title ? this.parent.current.clientWidth * 0.85 : this.parent.current.clientWidth;

    if (reDraw) {
      reDraw(this.props.speed)
    }
  }

  onResize = _.throttle(() => {
    this.resize(this.drawFrame);
  }, 500)

  componentDidMount() {
    this.resizeEventHdlr = window.addEventListener('resize', this.onResize);
    const { speed } = this.props
    if (speed < 0 || speed > 120) {
      return;
    } else {
      setTimeout(() => this.resize(this.drawFrame),0);
    }

  }

  componentWillUnmount() {
    
      window.removeEventListener('resize', this.onResize);
    
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.speed !== this.props.speed && nextProps.speed >= 0 && nextProps.speed <= 120) {
      this.drawFrame(nextProps.speed)
    }
  }

  drawFrame = (speed) => {
    /** @type {HTMLCanvasElement}  */
    const canvas = this.canvas.current;
    const cWidth = canvas.width;
    const cHeight = canvas.height;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, cWidth, cHeight);

    var dot = new this.Dot(),
      angle = (speed - 0) * this.deg1 / 24;  //根据比例计算弧度，当前值/120（总值） * 5段 * 每小段弧度

    ctx.save();
    ctx.clearRect(0, 0, cWidth, cHeight);
    ctx.translate(cWidth / 2, cHeight / 2 + 20);
    ctx.rotate(8 * this.deg0);

    dot.x = this.radius * Math.cos(angle);
    dot.y = this.radius * Math.sin(angle);

    dot.draw(ctx,this.props.color);

    this.text(speed, ctx);

    ctx.save(); //中间刻度层 只画已经过的
    ctx.beginPath();
    ctx.strokeStyle = this.props.color;
    ctx.lineWidth = 16;
    ctx.arc(0, 0, 46, 0, angle, false);
    ctx.stroke();
    ctx.restore();

    ctx.save(); // 刻度线
    this.scaleAngle = 0;
    for (var i = 0; i < 6; i++) {
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = this.scaleAngle < angle ? '#222222' : '#02BBF0';
      ctx.moveTo(50, 0);
      ctx.lineTo(39, 0);
      ctx.stroke();
      ctx.rotate(this.deg1);
      this.scaleAngle = this.scaleAngle + this.deg1;
    }
    this.scaleAngle = 0
    ctx.restore();

    ctx.save(); // 细分刻度线
    for (i = 0; i < 25; i++) {
      if (i % 5 !== 0) {
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = this.scaleAngle < angle ? '#222222' : '#02BBF0';
        ctx.moveTo(50, 0);
        ctx.lineTo(43, 0);
        ctx.stroke();
      }
      this.scaleAngle = this.scaleAngle + this.deg1 / 5;
      ctx.rotate(this.deg1 / 5);
    }
    this.scaleAngle = 0;
    ctx.restore();

    ctx.save(); //速度
    /* ctx.rotate(Math.PI / 2);
    for (i = 0; i < 6; i++) {
      ctx.fillStyle = 'rgba(255, 255, 255, .4)';
      ctx.font = '10px Microsoft yahei';
      ctx.textAlign = 'center';
      //ctx.fillText(400 + 100 * i, 0, -115);
      ctx.rotate(this.deg1);
    }
    ctx.restore();
 
    ctx.save(); //分数段 */
    ctx.rotate(Math.PI / 2 + this.deg0);
    for (i = 0; i < 5; i++) {
      ctx.fillStyle = 'rgba(255, 255, 255, .4)';
      ctx.font = '10px Microsoft yahei';
      ctx.textAlign = 'center';
      //ctx.fillText(this.stage[i], 5, -115);
      ctx.rotate(this.deg1);
    }
    ctx.restore();

    ctx.save(); //信用阶段及评估时间文字
    ctx.rotate(10 * this.deg0);
    ctx.fillStyle = '#fff';
    ctx.font = '10px Microsoft yahei';
    ctx.textAlign = 'center';

    ctx.fillText('KM/H', 0, 20);



    ctx.restore();


    ctx.save(); //最外层轨道
    ctx.beginPath();
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
    ctx.lineWidth = 2;
    ctx.arc(0, 0, this.radius, 0, 11 * this.deg0, false);
    ctx.stroke();
    ctx.restore();

    ctx.rotate(-8 * this.deg0);
    ctx.translate(-cWidth / 2, -cHeight / 2 - 20);

  }

  render() {
    return (
      <div className={styles.canvasWrapper} ref={this.parent}>
        {this.props.title && <div className={styles.canvasTitle}>{this.props.title}</div>}
        <canvas style={this.props.title ? { right: 0 } : { left: 0 }} className={styles.canvasElement} id="canvas" ref={this.canvas} height="400"></canvas>
      </div>
    )
  }
}