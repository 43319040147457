import React, { Component } from 'react'
import { Icon, InputNumber, message, Modal, Input, Select, Button } from 'antd'
import { connect } from 'react-redux'
import styles from './styles.module.css'
import EditBmap from './editMap'
import { post } from '../../../api/request'
import { QDTestFieldId } from '../../public/testFieldID'
import NumberInput from './numberInput'
import { bd09togcj02, gcj02tobd09, wgs84togcj02, gcj02towgs84 } from '../../../api/latLngTransform';

const confirm = Modal.confirm;
const InputGroup = Input.Group;
const Option = Select.Option;

const markerTypeImgSize = {
    "1": { width: 48, height: 48 },
    "2": { width: 25.8, height: 48 },
    "3": { width: 48, height: 48 },
    "4": { width: 34, height: 48 },
    "5": { width: 48, height: 48 },
}

//mode : 1.add 2.view
class EditPane extends Component {
    state = {
        isEdit: false,
        selectType: 1,
        speedLimitNumber: '',
        latlngType: 1, //1输入 2地图取点

        deviceLat: '',  //位置点硬件纬度
        deviceLng: '',  //位置点硬件经度

        direDeviceLat: '', //方向点硬件纬度
        direDeviceLng: '',//方向点硬件经度

        inputLngType: 'eLng',
        inputLatType: 'nLat',
        direLngType: 'eLng',
        direLatType: 'nLat',

        //地图
        markerPoint: null, //位置点坐标,
        directionPoint: null,
        allowToSign: false,
        number: null,
        mapSelectType: 'position', //position位置点 direction方向点

        id: null
    }

    componentDidMount() {
        const { mode, markerInfo } = this.props;
        if (mode === 'view') {
            //this.setState()
            //console.log(markerInfo)
            const { markType, param, latitude, longitude, id, pointLongitude, pointLatitude } = markerInfo
            const speed = markType === 1 && param && typeof param === 'string' ? JSON.parse(param).speed : ''
            //const bdTogcj = bd09togcj02(longitude, latitude)
            // const gcjToWgs = gcj02towgs84(bdTogcj.lng, bdTogcj.lat);

            const wgsTogcj = wgs84togcj02(longitude, latitude);
            const gcjTobd = gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat);

            const direWgsTogcj = pointLongitude && pointLatitude ? wgs84togcj02(pointLongitude, pointLatitude) : null;
            const direGcjTobd = direWgsTogcj ? gcj02tobd09(direWgsTogcj.lng, direWgsTogcj.lat) : null;

            if (longitude < 0) {
                this.setState({
                    inputLngType: 'wLng'
                })
            } else {
                this.setState({
                    inputLngType: 'eLng'
                })
            }

            if (latitude < 0) {
                this.setState({
                    inputLatType: 'sLat'
                })
            } else {
                this.setState({
                    inputLatType: 'nLat'
                })
            }

            this.setState({
                selectType: markType,
                speedLimitNumber: speed,
                markerPoint: new window.BMap.Point(gcjTobd.lng, gcjTobd.lat),
                directionPoint: direGcjTobd ? new window.BMap.Point(direGcjTobd.lng, direGcjTobd.lat) : null,
                number: speed,
                deviceLat: latitude,
                deviceLng: longitude,
                direDeviceLat: pointLatitude?pointLatitude:'',
                direDeviceLng: pointLongitude?pointLongitude:'',
                id
            })
        }
    }

    showMap = () => {
        const { mode } = this.props
        if (mode === 'view') {
            if (this.state.isEdit) {
                return this.state.latlngType === 2
            } else {
                return false;
            }
        } else if (mode === 'add') {
            return this.state.latlngType === 2
        } else {
            return false
        }
    }

    getTitle = () => {
        const { mode } = this.props
        if (this.state.isEdit) {
            return '编辑交通设施'
        }

        if (mode === 'view') {
            return '查看交通设施'
        } else if (mode === 'add') {
            return '创建交通设施'
        }
    }

    onTypePaneClick = (type) => (e) => {
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return;
        }
        this.setState({
            selectType: type.markerType
        })
    }

    onEditTabClick = (num) => (e) => {
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return;
        }

        this.setState({
            latlngType: num
        })
    }

    showInputNumber = (type) => {
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return false;
        } else {
            return type.markerType === 1 && this.state.selectType === type.markerType
        }
    }

    onAddCancel = () => {
        const { onClose } = this.props

        onClose();
    }

    /**
     *提交创建的marker
     *
     * @memberof EditPane
     */
    onAddConfirm = () => {
        const { markerPoint, selectType, speedLimitNumber, deviceLat, deviceLng, direDeviceLat, direDeviceLng, directionPoint } = this.state
        const { onClose } = this.props

        if (selectType === 1 && !speedLimitNumber) {
            message.info('请填写限速值');
            return;
        }

        if(!markerPoint || deviceLat === '' || deviceLat === null || deviceLng === '' || deviceLng === null){
            message.info('交通标识位置未设置');
            return ;
        }

        if(!directionPoint || direDeviceLat === '' || direDeviceLat === null || direDeviceLng === '' || direDeviceLng === null){
            message.info('交通标识方向点未设置');
            return ;
        }

        confirm({
            title: '确认添加吗？',
            content: '',
            onOk: () => {
                const data = {
                    latitude: deviceLat,
                    longitude: deviceLng,
                    pointLongitude: direDeviceLng, 
                    pointLatitude: direDeviceLat,
                    markType: selectType,
                    testFieldId: QDTestFieldId,
                    gpsType: 0
                }

                if (selectType === 1) {
                    data['param'] = {
                        speed: speedLimitNumber
                    }
                }

                //console.log(data)

                post('/testSite/testField/addTestMark', data).then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                        message.success('添加成功');
                        setTimeout(() => onClose()
                            , 500)
                    } else {
                        message.error('添加失败');
                    }
                }).catch(e => {
                    message.error('添加失败2');
                    console.log(e)
                })
            },
            onCancel: () => { }
        })
    }

    onViewCancel = () => {
        this.setState({
            isEdit: false
        })
    }

    editConfirm = () => {
        const { markerInfo, onClose } = this.props;
        const { id, testFieldId } = markerInfo;
        const { markerPoint, selectType, speedLimitNumber, deviceLat, deviceLng, directionPoint, direDeviceLat, direDeviceLng } = this.state;

        if (selectType === 1 && !speedLimitNumber) {
            message.info('请填写限速值');
            return;
        }

        if(!markerPoint || deviceLat === '' || deviceLat === null || deviceLng === '' || deviceLng === null){
            message.info('交通标识位置未设置');
            return ;
        }

        if(!directionPoint || direDeviceLat === '' || direDeviceLat === null || direDeviceLng === '' || direDeviceLng === null){
            message.info('交通标识方向点未设置');
            return ;
        }

        confirm({
            title: '确认修改吗',
            content: '',
            onOk: () => {
                const data = {
                    latitude: deviceLat,
                    longitude: deviceLng,
                    pointLongitude: direDeviceLng, 
                    pointLatitude: direDeviceLat,
                    markType: selectType,
                    testFieldId: testFieldId,
                    id,
                    gpsType: 0
                }

                if (selectType === 1) {
                    data['param'] = {
                        speed: speedLimitNumber
                    }
                }

                post('/testSite/testField/addTestMark', data).then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                        message.success('编辑成功');
                        setTimeout(() => onClose(), 500)
                    } else {
                        message.error('编辑失败');
                    }
                })
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    getFooter = () => {
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return null
        } else {
            if (this.props.mode === 'view' && this.state.isEdit) {
                return (
                    <div className={styles.editFooter}>
                        <button onClick={this.onViewCancel} className={styles.editCancelButton}>取消</button>
                        <button onClick={this.editConfirm} className={styles.editSaveButton}>保存</button>
                    </div>
                )
            }

            if (this.props.mode === 'add') {
                return (
                    <div className={styles.editFooter}>
                        <button onClick={this.onAddCancel} className={styles.editCancelButton}>取消</button>
                        <button disabled={!this.state.deviceLng || !this.state.deviceLat} onClick={this.onAddConfirm} className={styles.editSaveButton}>保存</button>
                    </div>
                )
            }
        }
    }

    onMapClick = (point) => {
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return;
        }

        if (this.state.selectType === 1 && this.state.speedLimitNumber === '') {
            message.info('请先填写限速值');
            return;
        }

        const bdTogcj = bd09togcj02(point.lng, point.lat);
        const gcjToWgs = gcj02towgs84(bdTogcj.lng, bdTogcj.lat)

        if (point.lng < 0) {
            if (this.state.mapSelectType === 'position') {
                this.setState({
                    inputLngType: 'wLng'
                })
            } else if (this.state.mapSelectType === 'direction') {
                this.setState({
                    direLngType: 'wLng'
                })
            }
        } else {
            if (this.state.mapSelectType === 'position') {
                this.setState({
                    inputLngType: 'eLng'
                })
            } else if (this.state.mapSelectType === 'direction') {
                this.setState({
                    direLngType: 'eLng'
                })
            }
        }

        if (point.lat < 0) {
            if (this.state.mapSelectType === 'position') {
                this.setState({
                    inputLatType: 'sLat'
                })
            } else if (this.state.mapSelectType === 'direction') {
                this.setState({
                    direLatType: 'sLat'
                })
            }
        } else {
            if (this.state.mapSelectType === 'position') {
                this.setState({
                    inputLatType: 'nLat'
                })
            } else if (this.state.mapSelectType === 'direction') {
                this.setState({
                    direLngType: 'nLat'
                })
            }
        }

        if (this.state.mapSelectType === 'position') {
            this.setState({
                markerPoint: point,
                deviceLat: gcjToWgs.lat,
                deviceLng: gcjToWgs.lng,

                directionPoint: null,
                direDeviceLat: null,
                direDeviceLng: null
            })
        } else if (this.state.mapSelectType === 'direction') {
            this.setState({
                directionPoint: point,
                direDeviceLat: gcjToWgs.lat,
                direDeviceLng: gcjToWgs.lng
            })
        }
    }

    /**
     *重新打点，设置speedLimitNumber:'',selectType:1,markerPoint:null
     *
     * @memberof EditPane
     */
    onReset = () => {
        //const { selectType, speedLimitNumber } = this.state
        const { mode, markerInfo } = this.props
        if (this.props.mode === 'view' && !this.state.isEdit) {
            return;
        }

        if (mode === 'add') {
            this.setState({
                markerPoint: null,
                selectType: 1,
                speedLimitNumber: '',
                number: null,
                directionPoint: null,
                direDeviceLat: '',
                direDeviceLng: '',
                deviceLat: '',  //位置点硬件纬度
                deviceLng: '',  //位置点硬件经度
            })
        } else if (mode === 'view' && this.state.isEdit) {
            const { markType, param, latitude, longitude, pointLongitude, pointLatitude } = markerInfo
            const speed = markType === 1 && param && typeof param === 'string' ? JSON.parse(param).speed : ''

            const wgsTogcj = wgs84togcj02(longitude, latitude);
            const gcjTobd = gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat);

            const direWgsTogcj = pointLongitude && pointLatitude ? wgs84togcj02(pointLongitude, pointLatitude) : null;
            const direGcjTobd = direWgsTogcj ? gcj02tobd09(direWgsTogcj.lng, direWgsTogcj.lat) : null;

            this.setState({
                selectType: markType,
                speedLimitNumber: speed,
                markerPoint: new window.BMap.Point(gcjTobd.lng, gcjTobd.lat),
                directionPoint: direGcjTobd ? new window.BMap.Point(direGcjTobd.lng, direGcjTobd.lat) : null,
                number: speed,
                direDeviceLat: pointLatitude?pointLatitude:'',
                direDeviceLng: pointLongitude?pointLongitude:'',
                deviceLat: latitude,  //位置点硬件纬度
                deviceLng: longitude,  //位置点硬件经度
            })
        }

    }

    /**
     *限速值输入失焦事件，更新speedLimitNumber，如果e.target.value为''且selectType为1，重置markerPoint为null
     * @param e event对象
     * @memberof EditPane
     */
    onInpuNumberBlur = (e) => {
        this.setState({
            speedLimitNumber: e.target.value,
            markerPoint: e.target.value === '' && this.state.selectType === 1 && this.state.markerPoint ? null : this.state.markerPoint
        })
    }

    onDeviceLngInputBlur = (value) => {
        //console.log(value)
        if (!this.state.deviceLat || !value) {
            return;
        }



        const wgsTogcj = wgs84togcj02(this.state.inputLngType === 'eLng' ? Number(value) : 0 - Number(value), Number(this.state.deviceLat))
        const gcjToBd = gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)

        // console.log(gcjToBd)

        this.setState({
            markerPoint: gcjToBd
        })
    }

    onDeviceLatInputBlur = (value) => {
        if (!this.state.deviceLng || !value) {
            return;
        }

        const wgsTogcj = wgs84togcj02(Number(this.state.deviceLng), this.state.inputLatType === 'nLat' ? Number(value) : 0 - Number(value))
        const gcjToBd = gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)

        this.setState({
            markerPoint: gcjToBd
        })
    }

    onDelete = () => {
        const { markerInfo, onClose } = this.props
        const { id } = markerInfo

        confirm({
            title: '确定要删除吗？',
            content: '',
            onOk: () => {
                post('/testSite/testField/delTestMark', { id }).then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                        message.success('删除成功');
                        setTimeout(() => onClose(), 500)
                    } else {
                        message.error('删除失败');
                    }
                }).catch(e => {
                    message.error('删除失败')
                    console.log(e)
                })
            },
            onCancel: () => { }
        })
    }

    render() {
        const { mode, onClose, allMarkerType, markerInfo } = this.props

        return (
            <div className={styles.editMain}>
                <div className={styles.editContent}>

                    <div className={styles.editHeader}>
                        <span className={styles.editClose}><Icon onClick={onClose} className={styles.editCloseIcon} type="close" /></span>
                        <div className={styles.editHeaderLeft}>
                            <span className={styles.editTitle}>{this.getTitle()}</span>
                            {mode == 'view' && !this.state.isEdit && <div className={styles.editButtonGroup}>
                                <button onClick={() => this.setState({ isEdit: true })} className={styles.editEditButton}>编辑</button>
                                <button onClick={this.onDelete} className={styles.editDeleteButton}>删除</button>
                            </div>}
                        </div>
                    </div>

                    <div className={styles.editBody}>
                        {mode !== 'add' && <div className={styles.editMarkerInfCon}>
                            <div className={styles.editMarkerInfo}>
                                <span className={styles.editMarkerInfoTitle}>设施编号</span>
                                <span className={styles.editMarkerInfoText}>{markerInfo.markNo}</span>
                            </div>
                        </div>}

                        <div className={styles.editMarkerTypeCon}>
                            <div className={styles.editMarkerTypeHeader}>设施类型</div>
                            <div className={styles.editMarkerTypeBody}>
                                {allMarkerType.map((type, index) =>
                                    <div onClick={this.onTypePaneClick(type)} className={this.state.selectType === type.markerType ? styles.editSelectTypePane : styles.editTypePane} key={index}>
                                        <div className={styles.editMarkerTypeImgWrapper}>
                                            <img style={markerTypeImgSize[type.markerType]} src={type.imgUrl} alt="" />
                                            {
                                                type.markerType === 1 &&
                                                <div className={styles.editSpeedLimit}>{this.state.speedLimitNumber}</div>
                                            }
                                        </div>
                                        <span className={styles.editMarkerText}>{type.markerName}</span>
                                        {this.showInputNumber(type) &&
                                            <InputNumber style={{ width: 80 }} value={this.state.number} onChange={(value) => this.setState({ number: value })} placeholder="限速值" min={0} max={300} onBlur={this.onInpuNumberBlur} className={styles.editSpeedInput} />
                                        }
                                    </div>)}
                            </div>
                        </div>

                        <div className={styles.editLatlngCon}>
                            <div className={styles.editLatlngHeader}>设备位置</div>
                            <div className={styles.editLatlngTabCon}>
                                <div onClick={this.onEditTabClick(1)} className={this.state.latlngType === 1 ? styles.editLatlngTabSelected : styles.editLatlngTab}>输入坐标</div>
                                <div onClick={this.onEditTabClick(2)} className={this.state.latlngType === 2 ? styles.editLatlngTabSelected : styles.editLatlngTab}>地图标记</div>
                            </div>
                            {this.state.latlngType === 2 && <div className={styles.editLatlngTabMap}>
                                <div className={styles.editLatlngTabLeft}>
                                    <div className={styles.editCircle}></div>
                                    <span style={{ fontSize: 12 }}>交通设施</span>
                                </div>
                                <div className={styles.editLatlngTabRight}>
                                    {/* <button className={styles.editLatlngSignButton}>标记</button> */}
                                    <button onClick={this.onReset} className={styles.editLatlngResignButton}>
                                        <Icon style={{ color: '#CECFD2' }} type="undo" />
                                        重新标记
                                        </button>
                                </div>
                            </div>}
                            {
                                this.state.latlngType === 1 &&
                                <div>
                                    <div className={styles.LatLngCon}>
                                        <div className={styles.latLngSelectCon}>
                                            <button
                                                style={{ width: '80%', margin: 0 }}
                                                className={this.state.mapSelectType === 'position' ? styles.greenButton : styles.silverButton}
                                                onClick={() => this.setState({ mapSelectType: 'position' })}
                                                disabled={mode === 'view' && !this.state.isEdit}
                                            >位置点</button>
                                        </div>
                                        <div className={styles.latlngInputCon}>
                                            <span className={styles.latlngInputHeader}>经度</span>
                                            <span className={styles.latlngInputHeader}>纬度</span>
                                            <InputGroup compact>
                                                <Select disabled={mode === 'view' && !this.state.isEdit} value={this.state.inputLngType} onChange={(value) => { this.setState({ inputLngType: value }) }} defaultValue="eLng">
                                                    <Option value="eLng">东经</Option>
                                                    <Option value="wLng">西经</Option>
                                                </Select>
                                                <NumberInput
                                                    style={{ width: '60%' }}
                                                    disabled={mode === 'view' && !this.state.isEdit}
                                                    onChange={(value) => this.setState({ deviceLng: value })}
                                                    value={Math.abs(this.state.deviceLng)}
                                                    onBlur={this.onDeviceLngInputBlur}
                                                />
                                            </InputGroup>

                                            <InputGroup compact>
                                                <Select disabled={mode === 'view' && !this.state.isEdit} value={this.state.inputLatType} onChange={(value) => { this.setState({ inputLatType: value }) }} defaultValue="nLat">
                                                    <Option value="nLat">北纬</Option>
                                                    <Option value="sLat">南纬</Option>
                                                </Select>
                                                <NumberInput
                                                    style={{ width: '60%' }}
                                                    disabled={mode === 'view' && !this.state.isEdit}
                                                    onChange={(value) => this.setState({ deviceLat: value })}
                                                    value={Math.abs(this.state.deviceLat)}
                                                    onBlur={this.onDeviceLatInputBlur}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>

                                    <div className={styles.LatLngCon}>
                                        <div className={styles.latLngSelectCon}>
                                            <button
                                                onClick={() => this.setState({ mapSelectType: 'direction' })}
                                                style={{ width: '80%', margin: 0 }}
                                                disabled={!this.state.markerPoint || (mode === 'view' && !this.state.isEdit)}
                                                className={this.state.mapSelectType === 'direction' ? styles.greenButton : styles.silverButton}
                                            >方向点
                                            </button>
                                        </div>
                                        <div className={styles.latlngInputCon}>
                                            <span className={styles.latlngInputHeader}>经度</span>
                                            <span className={styles.latlngInputHeader}>纬度</span>
                                            <InputGroup compact>
                                                <Select disabled value={this.state.inputLngType} onChange={(value) => { this.setState({ inputLngType: value }) }} defaultValue="eLng">
                                                    <Option value="eLng">东经</Option>
                                                    <Option value="wLng">西经</Option>
                                                </Select>
                                                <NumberInput
                                                    style={{ width: '60%' }}
                                                    disabled={true}
                                                    value={Math.abs(this.state.direDeviceLng)}
                                                />
                                            </InputGroup>

                                            <InputGroup compact>
                                                <Select disabled value={this.state.inputLatType} onChange={(value) => { this.setState({ inputLatType: value }) }} defaultValue="nLat">
                                                    <Option value="nLat">北纬</Option>
                                                    <Option value="sLat">南纬</Option>
                                                </Select>
                                                <NumberInput
                                                    style={{ width: '60%' }}
                                                    disabled={true}
                                                    value={Math.abs(this.state.direDeviceLat)}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>

                    </div>

                    {
                        this.getFooter()
                    }

                </div>
                {<div className={styles.editMapCon}>
                    <EditBmap
                        //id={this.state.id}
                        mode={this.props.mode}
                        markerPoint={this.state.markerPoint}
                        selectType={this.state.selectType}
                        speedLimitNumber={this.state.speedLimitNumber}
                        onMapClick={this.onMapClick}
                        directionPoint={this.state.directionPoint}
                    ></EditBmap>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { markerInfo } = state.siteConfig;
    const allMarkerType = []

    for (var i in markerInfo) {
        allMarkerType.push(markerInfo[i])
    }

    return {
        allMarkerType
    }
}

export default connect(mapStateToProps)(EditPane)