import React from 'react'
import { connect } from 'react-redux'
import * as realTimeActions from '../../../reducer/realTimeMonitor/actions'
import * as siteConfig from '../../../reducer/siteConfig/actions'
import { Map } from 'rc-bmap'
import styles from './styles.module.css';
import FSHeader from '../../public/component/fullScreenHeader'
import styleJson from '../../public/mapStyle.json'
import MapChild from './mapChilds'
import { QDTestFieldId } from '../../public/testFieldID'
import { isEqual } from 'lodash'

const fullScreen = (main) => {
  const fs = main.requestFullScreen || main.webkitRequestFullScreen || main.mozRequestFullScreen || main.msRequestFullscreen;
  fs.call(main)
}

const mapStyle = {
  styleJson
}

const cancelFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
}


class SiteMonitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.main = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props

    //OBE
    dispatch(realTimeActions.getOBEList(QDTestFieldId, 1));

    this.obeInterval = setInterval(() => {
      dispatch(realTimeActions.getOBEList(QDTestFieldId, 1));
    }, 1500)

    //RSE
    dispatch(realTimeActions.getRSEList(QDTestFieldId, 1));

    //交通标识
    dispatch(siteConfig.getAllMarkerInfo());
    dispatch(siteConfig.getSiteInfo(QDTestFieldId));

    //摄像头
    dispatch(realTimeActions.getCamList(QDTestFieldId, 1));
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.axisBdCenter, this.props.axisBdCenter)) {
        if (window.bMapInstance && nextProps.axisBdCenter) {
            const center = JSON.parse(nextProps.axisBdCenter)
            window.bMapInstance.centerAndZoom(new window.BMap.Point(center.wgLon, center.wgLat), 15)
        }
    }

}

  componentWillUnmount() {
    clearInterval(this.obeInterval)
    //clearInterval(this.siteFacilityInterval)
  }

  render() {

    return (
      <div ref={this.main} className={styles.main}>
        <FSHeader
          fullScreen={() => fullScreen(this.main.current)}
          fullScreenCancel={() => cancelFullScreen()}
          title='测试场实时监控'
        >
        </FSHeader>
        <div className={styles.content}>
          <div className={styles.mapCon}>
            <div className={`${styles.topLine}`}></div>
            <div className={`${styles.bottomLine}`}></div>
            <div className={`${styles.leftLine}`}></div>
            <div className={`${styles.rightLine}`}></div>
            <Map
              ak="FAcaea3b7506bd3769515d745c9b4bab"
              ref="map"
              scrollWheelZoom={true}
              zoom={15}
              center={new window.BMap.Point(112.208995163004, 32.12546515110927)}
              style={{ position: 'relative' }}
              //events={this.getMapEvents()}
              maxZoom={25}
              highResolution={true}
              mapStyle={mapStyle}
            >
             <MapChild></MapChild>
            </Map>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { siteInfo } = state.siteConfig;
  const { testField } = siteInfo;

  if (!testField) {
      return {}
  }

  const { axisBdCenter } = testField;

  return {
      axisBdCenter,
  }

}

export default connect(mapStateToProps)(SiteMonitor) 