import React from 'react'
import {Button,Icon,Tabs,Col} from 'antd'
import {sceneInfo} from '../../../api/sceneInfo'
import styles from './style.module.css'
import scene from './img/scene.png'
import backVehicle from './img/backVehicle.png'
import sceneNumIcon from './img/sceneNum.png'
import projectIcon from './img/project.png'
import SceneMapMark from './img/sceneMapMark.png'

const TabPane = Tabs.TabPane;

class ViewTestScene extends React.Component{
  state={
    activeKey:"2",
    sceneInfo:null
  }

  componentDidMount(){
   /*  if(this.props.sceneId){
      this.getSceneDataById()
    } */
  }

  getSceneDataById=()=>{
    let data={id:this.props.sceneId}
    sceneInfo.getSceneInfoById(data).then(res=>{
      if(res.data.code === 200){
        this.setState({sceneInfo:res.data.data})
      }
    })
  }
  callback=(key)=> {
    this.setState({activeKey:key})
    //console.log(key);
  }

  render(){
    return (
      <div>
        {/* <div className={styles.editSceneBtnCon}>
          <Button onClick={()=>{this.props.editChange(true,this.props.sceneInfo)}} className={styles.editSceneBtn}>编辑</Button>
        </div> */}

        <div className={styles.viewSceneItem}>
          <img src={sceneNumIcon} style={{width:'20px',height:'20px'}}/> <span className={styles.viewSceneTitle}>场景名称</span><span className={styles.viewSceneData}>{this.props.sceneInfo?this.props.sceneInfo.name:null}</span>
        </div>

        <div className={styles.viewSceneItem}>
          <img src={projectIcon} style={{width:'20px',height:'20px'}}/> <span className={styles.viewSceneTitle}>所属项目</span><span className={styles.viewSceneData}>{this.props.sceneInfo?this.props.sceneInfo.projectName:null}</span>
        </div>

        <div className={styles.viewSceneItem}>
          <img src={backVehicle} style={{width:'20px',height:'20px'}}/> <span className={styles.viewSceneTitle}>背景车辆</span><span className={styles.viewSceneData}>{this.props.sceneInfo ?(this.props.sceneInfo.backgroundVehicle?'需要':(this.props.sceneInfo.backgroundVehicle === 0?'不需要':'--')):'--'}</span>
        </div>

        <div className={styles.viewSceneItem} >
          <img src={sceneNumIcon} style={{width:'20px',height:'20px'}} /> <span className={styles.viewSceneTitle}>场景标识</span>
          <span className={styles.viewSceneData} >{this.props.sceneInfo?(this.props.sceneInfo.sceneMarkList?this.props.sceneInfo.sceneMarkList.map((v)=>{
            return (<span key={v.markNo}>{v.markName}(维护距离:{v.distanceList.map((item)=>{ return item+'米 '})})<br/></span>)
          }):null):null}</span>
        </div>

        <div className={styles.scene}>
          <Tabs defaultActiveKey={this.state.activeKey} activeKey={this.state.activeKey}  onChange={this.callback} style={{textAlign:'center',padding:'0px !important'}}>
            {/* <TabPane tab="测试场景" key="1" className={styles.sceneFillCon}>
              <Col span={12}>
                {this.props.sceneInfo?this.props.sceneInfo.description:null}
              </Col>
              <Col span={12} >
                {this.props.sceneInfo &&this.props.sceneInfo.imageIdList.length>0 &&
                  this.props.sceneInfo.imageIdList.map((v)=>{
                   return ( <div className={styles.imgLittle}><img key={v} src={`/testSite/scene/getImage?imageId=${v}`} /></div>)
                 })

               }
              </Col>
            </TabPane> */}
            <TabPane tab="测试方法" key="2" className={styles.sceneFillCon}>
              <Col span={24}>
                {this.props.sceneInfo?this.props.sceneInfo.testMethod:null}
              </Col>
            </TabPane>
            <TabPane tab="测试要求" key="3" className={styles.sceneFillCon}>
              <Col span={24}>
                {this.props.sceneInfo?this.props.sceneInfo.requirement:null}
              </Col>
            </TabPane>
          </Tabs>
        </div>

      </div>
    )
  }
}

export default  ViewTestScene