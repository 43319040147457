import React ,{ Component }from 'react';
import { Icon } from 'antd'
import flvjs from 'flv.js';
//import { testFiledAPI } from "../api/testField"
//import intl from 'react-intl-universal'
import homeStyle from './videoStyle.module.css'
//const HOST = process.env.NODE_ENV === 'production' ? 'live.wuhaolin.cn' : 'http://172.16.1.179:8080';
class VideoComponent extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            videoHandle:true,
            tip:"Loading",
            url:null,
            playFailed:false,
            lazyLoad:false,
            reconnectionTime:0,
            playFlag:true,
            flagCapture:false
        };
    }
    componentDidMount() {
        this.playVideo()
    }
    playVideo=()=>{
        // console.log(this.props.url,"url")
        let that = this
        if (flvjs.isSupported()) {
            //that.props.videoSpin(true)
            that.setState({loading:true})
            //console.log(this.props.url,"url")
            //that.player = document.getElementById("videoElement");
            that.player = this.refs["video"]
            //let videoAPI.video('/hls/cam.flv')
            this.flvPlayer = flvjs.createPlayer({
                type: 'flv',
                hasAudio:false,
                /*url:`http://${HOST}/hls/cam`*/
                // url: 'http://172.16.1.179:8080/hls/cam.flv'
                url:this.props.url
            });
            this.flvPlayer .attachMediaElement(this.player);
            this.flvPlayer .load();
            this.flvPlayer .play();
            that.addFlvPlayerListener();

            that.setState({videoHandle:that.listenerVideoStatus()})

        }
    }
    addFlvPlayerListener=()=>{
        let that = this
        this.flvPlayer.on("error",function(err){
            // console.log(err,"error111111111")

            that.closeFlvPlayer()
        })
        this.flvPlayer.on("recovered_early_eof",function(err){
            // console.log(err,"recovered_early_eof")
        })
    }
    closeVideoHandle=()=>{
        this.props.videoClose()
        this.flvPlayer.pause();
        this.flvPlayer.unload();
        this.flvPlayer.detachMediaElement();
        this.flvPlayer.destroy();
        this.flvPlayer = null;
        this.setState({loading:false,url:''})
        this.props.resetCameraInfo()


    }
    closeFlvPlayer=()=>{
        if(this.flvPlayer){
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
        }
        this.flvPlayer = null;
    }
    componentWillUnmount(){
        if(this.flvPlayer){
            this.flvPlayer.pause();
            this.flvPlayer.unload();
            this.flvPlayer.detachMediaElement();
            this.flvPlayer.destroy();
        }
        this.flvPlayer = null;
        this.setState = (state,callback)=>{
            return;
        };
        //this.setState({videoHandle:null,url:''})
    }
    listenerVideoStatus=()=>{
        let that = this
        /*setTimeout(()=>{
            if(flvPlayer && flvPlayer.mediaInfo && !flvPlayer.mediaInfo.hasVideo){
                that.setState({playFailed:true,loading:false,tip:'视频播放失败'})
            }
        },10000)*/
        /*this.refs['video'].addEventListener('progress', function() {
            var range = 0;
            var bf = that.refs['video'].buffered;
            var time = that.refs['video'].currentTime;

            while(!(bf.start(range) <= time && time <= bf.end(range))) {
                range += 1;
            }
            that.refs['video'].currentTime = that.refs['video'].buffered.end(range) - 0.01;
        });*/
        this.refs['video'].addEventListener(
            "pause",
            function() {
                // console.log("视频被暂停");
                //that.props.videoSpin(true)
                //that.setState({loading:true})
            },
            false
        );

        this.refs['video'].addEventListener(
            "playing",
            function() {
                //console.log("视频正在播放中");
                //that.props.videoSpin(false)
                if(!that.state.flagCapture){
                    that.captureImage()
                    that.setState({flagCapture:true})
                }
                that.setState({loading:false})
            },
            false
        );

        //any video error will fail with message
        this.refs['video'].addEventListener(
            "error",
            function(err) {
                // console.log("视频播放错误");
                //that.props.videoSpin(false)
                that.setState({loading:false,tip:"视频播放错误"})
            },
            false
        );

        //Download and playback status events
        //页面加载时出现
        this.refs['video'].addEventListener(
            "loadstart",
            function() {
                // console.log("视频正在加载中");
                that.setState({tip:"Loading"})
                //that.props.videoSpin(true)
                //that.props.videoTip('视频正在加载中')
                //that.setState({loading:false})
            },
            false
        );
        this.refs['video'].addEventListener(
            "stalled",
            function() {
                // console.log("在下载被中断三秒以上时引发.网络差时触发");
                //that.props.videoSpin(true)
                //that.setState({loading:false})
                //that.closeFlvPlayer()
                //that.playVideo()
            },
            false
        );
        this.refs['video'].addEventListener(
            "waiting",
            function() {
                //console.log("播放下一帧不可用时触发(断网或者缓冲情况下)");
                //that.props.videoSpin(true)
                //that.props.videoTip('视频正在缓冲中')
                //that.setState({loading:false})
            },
            false
        );
        this.refs['video'].addEventListener(
            "timeupdate",
            function(err) {
                //console.log(err,"timeupdate");
                //that.captureImage()
            },
            false
        );


    }
    videoPause=()=>{
        if(this.flvPlayer){
            // console.log(this.flvPlayer)
            this.flvPlayer.pause();
            this.setState({playFlag:false})
        }
    }
    videoPlay=()=>{
        if(this.flvPlayer){
            this.flvPlayer.play();
            this.setState({playFlag:true})
        }
    }
    captureImage=()=>{
        var canvas = document.createElement("canvas");
        canvas.width = this.refs["video"].videoWidth ;
        canvas.height = this.refs["video"].videoHeight ;
        canvas.getContext('2d').drawImage(this.refs["video"], 0, 0, canvas.width, canvas.height);
        this.refs["captureA"].href = canvas.toDataURL('image/png')
        //console.log(canvas.toDataURL('image/png'),"图片数据")
        this.refs["captureA"].download = 'file_' + new Date().getTime() + '.png'
        var event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        //this.refs["captureA"].dispatchEvent(event);
        /* console.log(this.props.nowCameraInfo,"11111111")
        let data ={id:this.props.nowCameraInfo.id,imgStr:canvas.toDataURL('image/png')} */
       // console.log(data,"data")
        //console.log(this.props.nowCameraInfo,"this.props.nowCameraInfo")
        /* testFiledAPI.videoSnapshot(data).then(res=>{
            if(res.data.code == 200){
                console.log("截图成功")
            }
        }) */



    }
    loadErrorMessage=(err)=>{
        console.log(err,"播放失败")
    }
    componentWillReceiveProps(nextProps) {


    }



        render()
        {
            return (

                <div style={{width:'100%',height:'100%'}}>

                    <div className={homeStyle.videoCon}>

                            <video  preload="none" id="videoElement" ref="video" controls={false} onError={this.loadErrorMessage}></video>
                            {this.state.playFailed &&<div className={homeStyle.failTip} >{"网络错误 播放失败"}</div>}
                            <div className={homeStyle.videoControlBar}>
                                {this.state.playFlag ?<Icon type="pause" className={homeStyle.videoBarIcon} onClick={this.videoPause}/>:<Icon type="caret-right" className={homeStyle.videoBarIcon} onClick={this.videoPlay}/>}
                                <a ref="captureA"><Icon type="instagram" onClick={this.captureImage} className={homeStyle.snapshot} /></a>
                            </div>

                    </div>

                </div>

            );
        }
}

export default VideoComponent;
