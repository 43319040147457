import React from 'react'
import styles from './styles.module.css'
import SiteStyles from '../siteMonitor/styles.module.css'
import { connect } from 'react-redux'
import Bmap from './Bmap'
import Gauge from './gauge'
import { Menu, Dropdown, Icon, message } from 'antd';
import VideoComponent from '../../public/component/videoComponent'
import BcSpeedChart from './bcSpeedChart'
import Chart from './charts'
import Signal from './signal'
import AccBar from './accBar'
import BcAccBar from './bcAccBar'
import FSHeader from '../../public/component/fullScreenHeader'
import VutAccChart from './vutAccHistoryChart'
import VtAccChart from './vtAccHistoryChart'
import moment from 'moment'
import * as mActions from '../../../reducer/realTimeMonitor/actions'
import * as siteConfActions from '../../../reducer/siteConfig/actions'
import { post } from '../../../api/request'
import { QDTestFieldId } from '../../public/testFieldID'

const SubMenu = Menu.SubMenu;

const fullScreen = (main) => {
  const fs = main.requestFullScreen || main.webkitRequestFullScreen || main.mozRequestFullScreen || main.msRequestFullscreen;
  fs.call(main)
}

const cancelFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  }
}

const eventEnMap = {
  rush_redlight: '闯红灯告警',
  glosa_info: '车速引导',
  construction_information: '道路施工',
  accident_ahead: '前方事故',
  vsl_information: '可变限速',
  curve_speedlimit_information: '弯道限速',
  signal_phase_and_time: '信号相位信息',
  accident_prone: '事故易发路段预警',
  emergency_brake: '紧急制动预警',
  emergency_car: '紧急车辆',
  intersection_crash: '交叉口碰撞预警',
  lane_change_information: '换道预警',
  pedestrain_information: '盲区预警',
  etc_information: 'ETC',
  online: '上线',
  side_blind_zone_warning: '盲区预警',
  "brake": "刹车",
  "park": "停车",
  "start": "启动",
  "follow_too_close": "前向碰撞预警",
  "control_loss_warning": '车辆失控预警',
  "left_turn_assist_information": '左转辅助预警',
  "abnormal_vehicle_warning": "异常车辆提醒",
  "do_not_pass_information": "逆向超车碰撞预警",
  "preempt_information": '特种车辆',
  "speed_limit":'限速标志',
  "give_way":'停车让行标志',
  "traffic_lane":'车道线',
  "Crosswalk":'人行横道线',
  "signal_lamp":'机动车信号灯',
  "direction_lamp":'方向指示信号灯',
  "barricade":"障碍物测试",
  "error_action":"误作用测试",
  "pedestrian_cross_road":"行人横穿马路"
}

const specialEventMap = {
  speed_limit:1,
  give_way:1,
  traffic_lane:1,
  Crosswalk:1,
  signal_lamp:1,
  direction_lamp:1,
  barricade:1,
  error_action:1,
  pedestrian_cross_road:1
}

class SceneMonitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      process: 0,
      selectCar: null,//当前选择车辆
      testItemList: [],
      selectProject: '',
      selectScene: '',
      selectRules: null,
      isPlay:false,
      selectSceneId:null,
      showVideo:false,
      nowBgColor:'light'
    }
    this.rollCon = React.createRef();
    this.main = React.createRef();
  }


  componentDidMount() {
    //console.log(data)
    const { dispatch, siteInfo, markerInfo, siteMonitorCamList,newVutEvent } = this.props
    console.log(newVutEvent,"newVutEvent")
    this.exist = true;
    dispatch(mActions.clearAllRealTimeData)

    post('/testSite/project/find', {}).then(res => {
      if (res.status === 200 && res.data.code === 200) {
        if (res.data.data.list && this.exist) {
          this.setState({
            testItemList: res.data.data.list
          })
        }
      }
    })

    dispatch(mActions.getAllVut());
    dispatch(mActions.getRSEList(QDTestFieldId, 1))

    if (JSON.stringify(siteInfo) === "{}" || JSON.stringify(markerInfo) === '{}') {
      dispatch(siteConfActions.getAllMarkerInfo())
      dispatch(siteConfActions.getSiteInfo(QDTestFieldId))
    }

    if (!siteMonitorCamList.length) {
      dispatch(mActions.getCamList(QDTestFieldId, 1))
    }

  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.exist = false;
    post('/testSite/monitor/stopSpecialEventCal',{
      id:this.state.selectCar,
      recordSceneId:this.state.selectSceneId
    })
    this.props.dispatch(mActions.clearAllRealTimeData)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newVutEvent.length > this.props.newVutEvent.length) {
      if(this.state.nowBgColor === 'light'){
          this.setState({nowBgColor:'dark'})
      }else{
        this.setState({nowBgColor:'light'})
      }
      setTimeout(() => {
        if (this.rollCon) {
          this.rollCon.current.scrollTop = this.rollCon.current.scrollHeight
        }
      }, 0)
    }
  }

  getEventName = (asp) => {
    const { warnDetail, warnType } = asp;
    if (!warnType.length) {
      return "当前速度"
    } else if (warnType[0] === 'vsl_information') {
      return "识别限速"
    } else if (warnType[0] === "enter") {
      return "场景触发"
    } else if (warnType[0] === "fixed") {
      return "固定位置点"
    } else if (warnType[0] === "leave") {
      return "场景结束"
    } else if (warnType[0] === "end") {
      return "测试结束"
    }
  }

  getSpecialEvent = (warnDetail,warnType) => {
    const { acceleration, distance, speed } = warnDetail;
    
    if(warnType === 'speed_limit'){
      return`${distance?`距离限速标志 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'give_way'){
      return`${distance?`距离停车让行标志 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'traffic_lane'){
      return `${distance?`距离弯道 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'Crosswalk'){
      return `${distance?`距离人行横道线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'signal_lamp'){
      return `${distance?`距离停止线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'direction_lamp'){
      return `${distance?`距离停止线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'barricade'){
      return `${distance?`距离前方障碍物 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'error_action'){
      return `${distance?`距离前方障碍物 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }else if(warnType === 'pedestrian_cross_road'){
      return `${distance?`距离人行横道线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
    }
  }

  getEventDetail = (e) => {
    const { warnDetail, warnType } = e;
     if (warnType[0] === 'light') {
      return `${warnDetail['light'].hazard_signal ? ' 警示灯亮' : ''} ${warnDetail['light'].left_turn_signal ? '左转灯亮' : ''} ${warnDetail['light'].right_turn_signal ? '右转灯亮' : ''}`
    } else if (warnType[0] === 'speed') {
      return `速度为${warnDetail['speed'].speed} 加速度为${warnDetail['speed'].acceleration}`
    } else if (warnType[0] === 'glosa_info') {
      return `${eventEnMap[warnType[0]]} ${typeof warnDetail.glosa_info === 'string' && JSON.parse(warnDetail.glosa_info)[0] && JSON.parse(warnDetail.glosa_info)[0].advisory_speed ? JSON.parse(warnDetail.glosa_info)[0].advisory_speed : '0'}KM/H`
    } else if (warnType[0] === 'vsl_information') {
      return `${eventEnMap[warnType[0]]} ${typeof warnDetail.vsl_information === 'string' && JSON.parse(warnDetail.vsl_information).advisory_speed ? JSON.parse(warnDetail.vsl_information).advisory_speed : '0'} KM/H`
    } else if (warnType[0] === 'curve_speedlimit_information') {
      return `${eventEnMap[warnType[0]]} ${typeof warnDetail.curve_speedlimit_information && JSON.parse(warnDetail.curve_speedlimit_information).curve_speedlimit_value ? JSON.parse(warnDetail.curve_speedlimit_information).curve_speedlimit_value : '0'} KM/H`
    } else if (specialEventMap[warnType[0]]){ //如果是特殊事件
      return this.getSpecialEvent(warnDetail[warnType[0]],warnType[0])
    }
    else {
      return eventEnMap[warnType[0]]
    }
  }

  getNewEventDetail=(e)=>{
    const { warnDetail, warnType } = e;
    let str = '';
     for(let i=0;i<warnType.length;i++){
       if(i !== warnType.length-1)
         str = str + warnDetail[warnType[i]]+' \n  '
       else
         str = str + warnDetail[warnType[i]]
    }
     return str
  }

  /**
   *选择待测车辆，请求车辆基本信息，返回待测车辆基本信息，返回背景车辆基本信息。请求实时数据
   *
   * @memberof SceneMonitor
   */
  onCarSelect = ({ item, key, keyPath }) => {
    const { dispatch } = this.props

    if (this.state.selectCar === key || key === "nocar") {
      return;
    }

    clearInterval(this.interval)
    dispatch(mActions.clearAllRealTimeData)

    this.setState({
      selectCar: key
    })

    dispatch(mActions.getCarInfo(key));

    /* setTimeout(() => {
      dispatch(mActions.getRealTimeData(key,false))
    },2000) */
  }

  getCarMenu = () => {
    const { allTestingVUT } = this.props

    return (
      <Menu onClick={this.onCarSelect}>
        {
          allTestingVUT.length ? allTestingVUT.map((car, index) => <Menu.Item key={car}>{car}</Menu.Item>) : <Menu.Item disabled key="nocar">无</Menu.Item>
        }
      </Menu>
    )
  }

  testItemMenuClick = ({ item, key, keyPath }) => {
    clearInterval(this.interval)

    this.props.dispatch(mActions.clearRealTimeDataExceptInfo)
    this.setState({
      selectProject: keyPath[1].split(",")[1],
      selectScene: keyPath[0].split(",")[1],
      selectSceneId: typeof key === 'string'?key.split(",")[0]:null,
      isPlay:false
    })

    post('/testSite/scene/find', { id: keyPath[0].split(",")[0] }).then(res => {
      if (res.status === 200 && res.data.code === 200) {
        if (res.data.data.list && res.data.data.list.length) {
          this.setState({
            selectRules: res.data.data.list[0]
          })
        }
      }
    })
  }

  startTest = () => {
    let that = this
    const { dispatch } = this.props
    post('/testSite/monitor/startSpecialEventCal',{
      id:this.state.selectCar,
      recordSceneId:this.state.selectSceneId
    }).catch(e => {
      message.info('计算特殊事件失败')
    })
    dispatch(mActions.getRealTimeData(this.state.selectCar, true))
    dispatch(mActions.getSpecialEvents(that.state.selectCar,true))

    this.interval = setInterval(() => {
      if (!this.exist) {
        return;
      }

      dispatch(mActions.getRealTimeData(this.state.selectCar, false))
      dispatch(mActions.getSpecialEvents(that.state.selectCar,false))
    }, 500)


    this.setState({
      isPlay:true,
      showVideo:true,
    })
  }

  stopTest = () => {
    clearInterval(this.interval)
    post('/testSite/monitor/stopSpecialEventCal',{
      id:this.state.selectCar,
      recordSceneId:this.state.selectSceneId
    })
    this.setState({
      isPlay:false
    })
  }

  render() {
    const { VUTInfo, info, vutEvent,newVutEvent } = this.props;
    console.log(newVutEvent,"newVutEvent")
    const { inVtCameraId, inVutCameraId, vutObeId, vtObeId, vtId } = VUTInfo;

    const menu = (
      <Menu onClick={this.testItemMenuClick} getPopupContainer={() => this.main.current}>
        {
          this.state.testItemList.map(project => {
            return (
              <SubMenu key={`${project.id},${project.name}`} title={project.name}>
                {project.scenes && project.scenes.length && project.scenes.map((scene, index) => {
                  return (
                    <Menu.Item key={`${scene.id},${scene.name}` || index}>{scene.name}</Menu.Item>
                  )
                })}
              </SubMenu>
            )
          })
        }
      </Menu>
    );

    //console.log(info)

    return (
      <div ref={this.main} className={styles.main}>
        {/* <header className={styles.header}>场景测试实时监控 <button onClick={() => this.main.current.requestFullscreen()}>全屏</button>   </header> */}
        <FSHeader
          fullScreen={() => fullScreen(this.main.current)}
          fullScreenCancel={() => cancelFullScreen()}
          title='场景测试实时监控'
        >
        </FSHeader>
        <div className={styles.content}>
          <div className={`${styles.contentChild} ${styles.leftContent}`}>
            <div className={styles.leftTop}>
              <div className={styles.cuCarTab}>

                <div className={styles.carDetailCon}>
                  <Dropdown onClick={() => this.props.dispatch(mActions.getAllVut())} trigger={['click']} overlay={this.getCarMenu()} getPopupContainer={() => this.main.current}>
                    <div style={{ cursor: 'pointer' }} className={`${styles.carDetail} ${styles.detailTab} ${styles.leftCorner} `}>
                      待测车辆 - {this.state.selectCar ? `${this.state.selectCar}` : '--'}
                    </div>
                  </Dropdown>
                  <div className={`${styles.carDetail} ${styles.detailContent2}`}>
                    <span className={`${styles.leftDetailTitle}`}>车载设备ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={vutObeId}>{vutObeId || '--'}</span>
                  </div>
                  <div className={`${styles.carDetail} ${styles.detailContent1}`}>
                    <span className={`${styles.leftDetailTitle}`}>车载监控ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={inVutCameraId}>{inVutCameraId || '--'}</span>
                  </div>
                  {/* <div className={`${styles.carDetail} ${styles.detailContent2}`}>
                    <span className={`${styles.detailTitle}`}>完成测试场景</span><span className={`${styles.detailText}`}></span>
                  </div> */}
                </div>

                <div className={styles.graphCon}>
                  {/* 仪表盘 */}
                  <div className={styles.gaugeCon}>
                    {<Gauge color={'rgba(2,187,240,1)'} speed={info && info.vutMoitor && info.vutMoitor.speed ? info.vutMoitor.speed : 0} title="实时速度"></Gauge>}
                  </div>

                  <div className={styles.speedHistoryChart}>
                    <Chart speed={0} title="速度历史"></Chart>
                  </div>

                  <div className={styles.speedHistoryChart}>
                    <AccBar title={true}></AccBar>
                  </div>

                  <div style={{ width: '100%', height: '25%' }}>
                    <VutAccChart title="加速度历史"></VutAccChart>
                  </div>
                </div>
              </div>
              <div className={styles.bacCarTab}>
                <div className={styles.carDetailCon}>
                  <div className={`${styles.carDetail} ${styles.detailTab} ${styles.rightCorner}`}>
                    背景车辆 - {vtId || "--"}
                  </div>
                  <div className={`${styles.carDetail} ${styles.detailContent2}`}>
                    <span className={`${styles.leftDetailTitle}`}>车载设备ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={vtObeId}>{vtObeId || '--'}</span>
                  </div>
                  <div className={`${styles.carDetail} ${styles.detailContent1}`}>
                    <span className={`${styles.leftDetailTitle}`}>车载监控ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={inVtCameraId}>{inVtCameraId || "--"}</span>
                  </div>

                </div>
                {/* 仪表盘 */}
                <div className={styles.graphCon}>
                  <div className={styles.gaugeCon}>
                    {<Gauge color={'rgba(255,193,67, 1)'} speed={info && info.vtMoitor && info.vtMoitor.speed ? info.vtMoitor.speed : 0}></Gauge>}
                  </div>

                  <div className={styles.speedHistoryChart}>
                    <BcSpeedChart></BcSpeedChart>
                  </div>

                  <div className={styles.speedHistoryChart}>
                    <BcAccBar></BcAccBar>
                  </div>

                  <div style={{ width: '100%', height: '25%' }}>
                    <VtAccChart></VtAccChart>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.leftBottom}>
              <div className={styles.yellowTitle}>当前路口信号灯</div>
              <Signal></Signal>
            </div>
          </div>

          <div className={`${styles.contentChild} ${styles.midContent}`}>
            <div className={styles.mapCon}>
              <div className={`${SiteStyles.topLine}`}></div>
              <div className={`${SiteStyles.bottomLine}`}></div>
              <div className={`${SiteStyles.leftLine}`}></div>
              <div className={`${SiteStyles.rightLine}`}></div>
              {this.state.selectCar && this.state.selectSceneId && <div className={`${styles.playBar}`}>
                {this.state.isPlay ?
                  /* <Icon
                    style={{ fontSize: 20, color: '#095CE5', cursor: 'pointer' }}
                    type="pause-circle"
                    onClick={this.onPasePlay}
                  /> */
                  <button onClick={this.stopTest} className={styles.endBtn}>停止测试</button>
                  :
                  /* <Icon
                    style={{ fontSize: 20, color: '#095CE5', cursor: 'pointer' }}
                    type="play-circle"
                    onClick={this.onPlayClick}
                  /> */
                  <button onClick={this.startTest} className={styles.playBtn} >开始测试</button>
                }
              </div>}
              <Bmap process={this.state.process} />
            </div>

            {/* <div className={styles.leftBottom}>
              <div className={styles.yellowTitle}>当前路口信号灯</div>
              <Signal></Signal>
            </div> */}

            <div className={styles.OutervideoCon}>
              <header className={styles.videoHeader}>
                <span className={styles.videoHeaderText}>车载车内视频监控</span>
                <span className={styles.videoHeaderText}>车载车外视频监控</span>
              </header>
              <div className={styles.outerVideoContent}>
                <div className={styles.singleVideo} style={{ marginRight: 5 }}>
                  {VUTInfo && VUTInfo.inVutCameraUrl && this.state.showVideo && <VideoComponent
                    key={1}
                    //url={'https://flvopen.ys7.com:9188/openlive/428facb643144812bd72e4cf411c2560.flv'} 
                    url={VUTInfo.inVutCameraUrl}
                    videoClose={() => { }}
                    resetCameraInfo={() => { }}>
                  </VideoComponent>}
                </div>
                <div className={styles.singleVideo}>
                  {VUTInfo && VUTInfo.outVutCameraUrl && this.state.showVideo && <VideoComponent
                    key={2}
                    //url={'https://flvopen.ys7.com:9188/openlive/8f1b757457b8433d9e05d8941deff1ef.flv'} 
                    url={VUTInfo.outVutCameraUrl}
                    videoClose={() => { }}
                    resetCameraInfo={() => { }}>
                  </VideoComponent>}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.contentChild} ${styles.rightContent}`}>
            <div className={styles.rightTabs}>
              <Dropdown trigger={['click']} overlay={menu} getPopupContainer={() => this.main.current}>
                <div style={{ cursor: 'pointer' }} className={`${styles.rightDetailTab} ${styles.leftCorner}`}>
                  <span className={`${styles.detailText} ${styles.noWrapText} ${styles.tabText}`} title={this.state.selectProject || ''}>测试项目：{this.state.selectProject || '--'}</span>
                </div>
              </Dropdown>
              <div className={`${styles.rightDetailTab} ${styles.rightCorner}`}>
                <span className={`${styles.detailText} ${styles.noWrapText}  ${styles.tabText}`} title={this.state.selectScene || ''}>场景测试：{this.state.selectScene || '--'}</span>
              </div>
            </div>

            <div className={styles.rightTwoCon}>
              <div className={styles.testRulesCon}>
                <div className={styles.yellowTitle}>场景测试规程列表</div>
                <div style={{ height: 'calc(100% - 56px)', width: '100%', display: 'flex' }}>
                  <div className={styles.rulesLeft}>
                    {/* <div style={{ height: '18%', textAlign: 'center' }} className={styles.detailContent2}>
                      <span className={`${styles.detailTitle}`}>测试项目：</span>
                    </div>
                    <div style={{ height: '18%', textAlign: 'center' }} className={styles.detailContent1}>
                      <span className={`${styles.detailTitle}`}>测试场景：</span>
                    </div> */}
                    <div style={{ height: '50%', textAlign: 'center' }} className={styles.detailContent2}>
                      <span className={`${styles.detailTitle}`}>测试方法：</span>
                    </div>
                    <div style={{ height: '50%', textAlign: 'center' }} className={styles.detailContent1}>
                      <span className={`${styles.detailTitle}`}>测试要求：</span>
                    </div>
                  </div>
                  <div className={styles.rulesRight}>
                    {/* <div style={{ height: '18%', textAlign: 'left', fontSize: 14, backgroundColor: 'rgba(3,221,253,0.3)', padding: '12px 14px' }} className={styles.detailText}>
                      <span className={styles.detailText}>交通标志与标线</span>
                    </div>
                    <div style={{ height: '18%', textAlign: 'left', fontSize: 14, backgroundColor: 'rgba(3,221,253,0.5)', padding: '12px 14px' }} className={styles.detailText}>
                      <span className={styles.detailText}>限速标志</span>
                    </div> */}
                    <div style={{ backgroundColor: 'rgba(3,221,253,0.3)' }} className={styles.detailTextCon}>
                      {/* <span className={styles.detailText}>1.距离限速标志100m前到达限速标志所示速度的1.2倍</span> <br />
                      <span className={styles.detailText}>2.匀速驶向限速标志</span> */}
                      <span className={styles.detailText}>
                        {
                          this.state.selectRules && this.state.selectRules.testMethod
                        }
                      </span>
                    </div>
                    <div style={{ backgroundColor: 'rgba(3,221,253,0.5)' }} className={styles.detailTextCon}>
                      {/* <span className={styles.detailText}>到达限速标志时，车速应不高于限速标志所示速度</span> */}
                      {
                        this.state.selectRules && this.state.selectRules.requirement.split("；").map((rule, index) => {
                          return (
                            <div key={index} className={styles.detailText}>
                              {rule}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.RollingeventsCon}>
                <div className={styles.yellowTitle}>
                  场景测试实时事件
                </div>
                <div className={styles.eventDetailCon}>
                  <div className={styles.listHeader}>
                    <span className={`${styles.eventListText} ${styles.evnetHeaderDetail}`}>事件说明</span>
                    <span className={`${styles.eventListText} ${styles.evnetHeaderTime}`}>事件时间</span>
                  </div>

                  <div ref={this.rollCon} className={styles.eventListCon}>

                    {/* <span className={`${styles.detailTitle} ${styles.evnetHeaderName}`}>事件名称</span>
                      <span className={`${styles.detailText} ${styles.evnetHeaderDetail}`}>事件说明</span>
                      <span className={`${styles.eventTimeText} ${styles.evnetHeaderTime}`}></span> */}

                    {
                      newVutEvent.map((event, index) =>
                        event ? event.warnType && event.warnType.length>0 && <div key={index} className={ styles.detailConLight}>
                          <span className={`${styles.detailText} ${styles.evnetHeaderDetail}`} title={this.getNewEventDetail(event)} >{this.getNewEventDetail(event)}</span>
                          <span className={`${styles.eventTimeText} ${styles.evnetHeaderTime}`} title={event.time ? moment(event.time).format('HH:mm:ss') : ''}>{event.time ? moment(event.time).format('HH:mm:ss') : ''}</span>
                        </div> :null
                      )
                    }


                    {/* {
                       vutEvent.map((event, index) =>
                        event &&  event.warnType && event.warnType.length>0 ?  <div key={index} className={index % 2 === 0 ? styles.detailConDark : styles.detailConLight}>
                          <span className={`${styles.detailText} ${styles.evnetHeaderDetail}`} title={this.getNewEventDetail(event)} >{this.getNewEventDetail(event)}</span>
                          <span className={`${styles.eventTimeText} ${styles.evnetHeaderTime}`} title={event.time ? moment(event.time).format('HH:mm:ss') : ''}>{event.time ? moment(event.time).format('HH:mm:ss') : ''}</span>
                        </div> :null
                      )
                    } */}

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  const { siteMonitorCamList, allTestingVUT, VUTInfo, realTimeData, vutEvent } = state.realTime;
  const { siteInfo, markerInfo } = state.siteConfig;
  const { veh, vt, info } = realTimeData;

  let vutEventArray = vutEvent.concat()

  if (vutEvent.length <= 5) {
    for (let i = 0; i <= 5 - vutEvent.length; i++) {
      vutEventArray = vutEventArray.concat(null);
    }
  }

  return {
    siteMonitorCamList,
    allTestingVUT,
    siteInfo,
    markerInfo,
    VUTInfo,
    realTimeData,
    veh,
    vt,
    info,
    vutEvent: vutEventArray,
    newVutEvent:vutEvent
  }
}

export default connect(mapStateToProps)(SceneMonitor)