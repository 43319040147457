import React, { Component } from 'react';
import { Map, Marker, Polygon, Label } from 'rc-bmap';
import { connect } from 'react-redux';
import styles from './styles.module.css';
import { wgs84togcj02, gcj02tobd09 } from '../../../api/latLngTransform'

class EditBmap extends Component {
    getMapEvents = () => {
        const { onMapClick, selectType, speedLimitNumber } = this.props
        return {
            click: (e) => {
                onMapClick(e.point);
            }
        }
    }

    /**
    *DB保存的是硬件坐标，要转换为百度坐标
    *
    * @memberof EditBmap
    */
    getMarkerPoint = (lat, lng) => {
        const wgsTogcj = wgs84togcj02(lng, lat);
        //const gcjToBd = gcj02tobd09(wgsTogcj.lng,wgsTogcj.lat)
        return gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)
    }

    render() {
        const { axisBd, markerInfo, markerPoint, selectType, speedLimitNumber, testMark, mode, directionPoint } = this.props

        const path = axisBd ? JSON.parse(axisBd).map(el => ({ lat: el.wgLat, lng: el.wgLon })) : []

        return (
            <div className={styles.editMapCon}>
                <Map
                    ak="FAcaea3b7506bd3769515d745c9b4bab"
                    ref="map"
                    scrollWheelZoom={true}
                    zoom={15}
                    center={new window.BMap.Point(112.208995163004, 32.12546515110927)}
                    //center={new window.BMap.Point(114.19030353797993, 30.500417893697907)}
                    style={{ position: 'relative' }}
                    events={this.getMapEvents()}
                    maxZoom={25}
                    highResolution={true}
                >
                    <Polygon
                        points={path}
                        strokeColor='#2CABE3'
                        strokeWeight={2}
                        strokeOpacity={1}
                        strokeStyle={'solid'}
                        fillColor="rgba(44,171,227,0.3)"
                    >
                    </Polygon>

                    {
                        markerPoint && markerInfo && selectType && <Marker
                            point={markerPoint}
                            offset={{ width: 0, height: -10 }}
                            icon={{ url: markerInfo[selectType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                            label={selectType === 1 ? <Label
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: 12,
                                    fontWeight: 'bold'
                                }}
                                content={speedLimitNumber}
                                offset={{ width: 2.5, height: 2.5 }} /> : null}
                            shadow={{ url: markerInfo[selectType].imgUrl, size: { width: 0, height: 0 } }}
                        >
                        </Marker>
                    }

                    {
                        directionPoint && <Marker
                            point={directionPoint}
                            //offset={{ width: 0, height: -10 }}
                            //icon={{ url: markerInfo[selectType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                            /* label={selectType === 1 ? <Label
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: 12,
                                    fontWeight: 'bold'
                                }}
                                content={speedLimitNumber}
                                offset={{ width: 2.5, height: 2.5 }} /> : null}
                            shadow={{ url: markerInfo[selectType].imgUrl, size: { width: 0, height: 0 } }} */
                        >
                        </Marker>
                    }

                    {
                        mode === 'add' && markerInfo && JSON.stringify(markerInfo) !== '{}' && testMark && testMark.map((marker, index) =>
                            <Marker
                                key={marker.id}
                                // point={new window.BMap.Point(marker.longitude, marker.latitude)}
                                point={this.getMarkerPoint(marker.latitude, marker.longitude)}
                                offset={{ width: 0, height: -10 }}
                                icon={{ url: markerInfo[marker.markType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                                label={marker.markType === 1 ? <Label
                                    style={{
                                        border: 'none',
                                        background: 'transparent',
                                        fontSize: 12,
                                        fontWeight: 'bold'
                                    }}
                                    content={marker.param ? JSON.parse(marker.param).speed : 0}
                                    offset={{ width: 2.5, height: 2.5 }} /> : null}
                                shadow={{ url: markerInfo[marker.markType].imgUrl, size: { width: 0, height: 0 } }}
                            //events={this.getMarkerEvent(marker)}
                            >
                            </Marker>)
                    }

                </Map>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { siteInfo, markerInfo } = state.siteConfig;
    const { testField, testMark } = siteInfo;

    if (!markerInfo) {
        return {}
    }

    if (!testField || !testMark) {
        return {}
    }

    const { axisBdCenter, axisBd } = testField;

    return {
        axisBdCenter,
        axisBd,
        markerInfo,
        testMark
    }
}

export default connect(mapStateToProps)(EditBmap)