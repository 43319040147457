import React, { Component } from 'react'
import moment from 'moment'
import styles from './clock.module.css'

export default class Clock extends Component {
    state = {
        time: null
    }

    componentDidMount() {
        this.alive = true
        this.interval = setInterval(() => {
            if (!this.alive) {
                clearInterval(this.interval)
                return ;
            }
            this.setState({
                time: moment().format('YYYY-MM-DD HH:mm:ss')
            })
        }, 1000)
    }

    componentWillUnmount() {
        this.alive = false;
        clearInterval(this.interval)
    }

    render() {
        return (
            <div className={styles.clock}>
                {this.state.time}
            </div>
        )
    }
}
