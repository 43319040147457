import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Map } from 'rc-bmap';
import { wgs84togcj02, gcj02tobd09 } from '../../../api/latLngTransform'
import styleJson from '../../public/mapStyle.json'
import MapChild from './mapChild'
import { isEqual } from 'lodash'

const mapStyle = {
    styleJson
}

class Bmap extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.map = React.createRef()
    }

    componentDidMount(){
        if(this.props.axisBdCenter){
            const center = JSON.parse(this.props.axisBdCenter)
            window.bMapInstance.centerAndZoom(new window.BMap.Point(center.wgLon, center.wgLat), 15)
        }
    }



    componentWillReceiveProps(nextProps) {
        const { veh } = nextProps
        const vuTcenter = veh && veh.vutData && veh.vutData ? new window.BMap.Point(veh.vutData.longitude, veh.vutData.latitude) : null

        if (!isEqual(nextProps.axisBdCenter, this.props.axisBdCenter)) {
            if (window.bMapInstance && nextProps.axisBdCenter) {
                const center = JSON.parse(nextProps.axisBdCenter)
                window.bMapInstance.centerAndZoom(new window.BMap.Point(center.wgLon, center.wgLat), 15)
            }
        }

        if (vuTcenter && window.bMapInstance) {
            window.bMapInstance.centerAndZoom(vuTcenter, this.map.current.map ? this.map.current.map.getZoom() : 15)
        }
    }

    getMarkerPoint = (lat, lng) => {
        const wgsTogcj = wgs84togcj02(lng, lat);
        //const gcjToBd = gcj02tobd09(wgsTogcj.lng,wgsTogcj.lat)
        return gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)
    }

    shouldComponentUpdate(nextProps,nextState){
        return false
    }

    render() {
    

        return (
            <div style={{ width: '100%', height: '100%' }}>
                <Map
                    ak="FAcaea3b7506bd3769515d745c9b4bab"
                    ref={this.map}
                    scrollWheelZoom={true}
                    zoom={15}
                    center={new window.BMap.Point(112.208995163004, 32.12546515110927)}
                    style={{ position: 'relative' }}
                    //events={this.getMapEvents()}
                    maxZoom={25}
                    highResolution={true}
                    mapStyle={mapStyle}
                >

                    {/* 标志 */}
                    <MapChild></MapChild>


                </Map>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { realTimeData} = state.realTime;
    const { siteInfo } = state.siteConfig;
    const { testField } = siteInfo;
    const { veh } = realTimeData;

    const axisBdCenter  = testField?testField.axisBdCenter:null;

    return {
        veh,
        axisBdCenter
    }
}

export default connect(mapStateToProps)(Bmap)