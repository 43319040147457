import React from 'react'
import styles from '../../realTimeMonitor/sceneMonitor/styles.module.css'
import { connect } from 'react-redux'
import Bmap from '../../realTimeMonitor/sceneMonitor/Bmap'
import Gauge from '../../realTimeMonitor/sceneMonitor/gauge'
import { Menu, Dropdown, Icon } from 'antd';
//import VideoComponent from '../../realTimeMonitor/sceneMonitor/videoComponent'
import BcSpeedChart from '../../realTimeMonitor/sceneMonitor/bcSpeedChart'
import SiteStyles from '../../realTimeMonitor/siteMonitor/styles.module.css'
import Chart from '../../realTimeMonitor/sceneMonitor/charts'
import Signal from '../../realTimeMonitor/sceneMonitor/signal'
import AccBar from '../../realTimeMonitor/sceneMonitor/accBar'
import BcAccBar from '../../realTimeMonitor/sceneMonitor/bcAccBar'
import FSHeader from '../../public/component/playbackHeader'
import VutAccChart from '../../realTimeMonitor/sceneMonitor/vutAccHistoryChart'
import VtAccChart from '../../realTimeMonitor/sceneMonitor/vtAccHistoryChart'
import moment from 'moment'
import * as mActions from '../../../reducer/realTimeMonitor/actions'
import * as siteConfActions from '../../../reducer/siteConfig/actions'
import * as pbActions from '../../../reducer/playback/actions'
import { post } from '../../../api/request'
import { QDTestFieldId } from '../../public/testFieldID'
import { isEqual } from 'lodash'

const SubMenu = Menu.SubMenu;

const eventEnMap = {
    rush_redlight: '闯红灯告警',
    glosa_infomation: '车速引导',
    construction_information: '道路施工',
    accident_ahead: '前方事故',
    vsl_information: '可变限速',
    curve_speedlimit_information: '弯道限速',
    signal_phase_and_time: '信号相位信息',
    accident_prone: '事故易发路段预警',
    emergency_brake: '紧急制动预警',
    emergency_car: '紧急车辆',
    intersection_crash: '交叉口碰撞预警',
    lane_change_information: '换道预警',
    pedestrain_information: '盲区预警',
    etc_information: 'ETC',
    online: '上线',
    side_blind_zone_warning: '盲区预警',
    "brake": "刹车",
    "park": "停车",
    "start": "启动",
    "follow_too_close": "前向碰撞预警",
    "control_loss_warning": '车辆失控预警',
    "left_turn_assist_information": '左转辅助预警',
    "abnormal_vehicle_warning": "异常车辆提醒",
    "do_not_pass_information": "逆向超车碰撞预警",
    "preempt_information": '特种车辆'
}
const specialEventMap = {
  speed_limit:1,
  give_way:1,
  traffic_lane:1,
  Crosswalk:1,
  signal_lamp:1,
  direction_lamp:1,
  barricade:1,
  error_action:1,
  pedestrian_cross_road:1
}

class SceneMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            process: 0,
            selectCar: null,//当前选择车辆
            testItemList: [],
            selectProject: '',
            selectScene: '',
            selectRules: null,
            VUTInfo: {},
            showProjectDrawer: true,
            scheme: [],
            selectSceneId: '',
            playInfo: {},
            isPlay: true
        }
        this.rollCon = React.createRef();
        this.main = React.createRef();
        this.count = 0
    }


    componentDidMount() {
        //console.log(data)
        const { dispatch, siteInfo, markerInfo, siteMonitorCamList, playbackId } = this.props
        this.exist = true;

        post('/testSite/project/find', {}).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                if (res.data.data.list && this.exist) {
                    this.setState({
                        testItemList: res.data.data.list
                    })
                }
            }
        })

        post('/testSite/history/getHistoryInfo', { id: playbackId }).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                this.setState({
                    VUTInfo: res.data.data
                })
            }
        })
        post('/testSite/history/getSceneTestPlan', { id: playbackId }).then(res => {
            if (res.status === 200 && res.data.code === 200 && res.data.data.length) {
                this.setState({
                    scheme: res.data.data
                })
            }
        })

        //dispatch(mActions.getAllVut());
        dispatch(mActions.getRSEList(QDTestFieldId, 1))

        if (JSON.stringify(siteInfo) === "{}" || JSON.stringify(markerInfo) === '{}') {
            dispatch(siteConfActions.getAllMarkerInfo())
            dispatch(siteConfActions.getSiteInfo(QDTestFieldId))
        }

        if (!siteMonitorCamList.length) {
            dispatch(mActions.getCamList(QDTestFieldId, 1))
        }

        dispatch(pbActions.clearFakRealData())

    }

    componentWillUnmount() {
        clearInterval(this.interval)
        this.exist = false;
        this.props.dispatch(pbActions.clearData())
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch } = this.props
        const sceneData = nextProps.sceneData
        const sceneDataLength = sceneData.length
        if (nextProps.vutEvent.length > this.props.vutEvent.length) {
            setTimeout(() => {
                this.rollCon.current.scrollTop = this.rollCon.current.scrollHeight
            }, 0)
        }

        if (!isEqual(sceneData, this.props.sceneData) && sceneData.length) {
            clearInterval(this.interval)
            this.count = 1;
            dispatch(pbActions.clearFakRealData())
            dispatch(pbActions.dispatchSceneData(sceneData[0]))
            this.setState({
                isPlay: true
            })
            this.interval = setInterval(() => {
                if (this.count <= sceneDataLength - 1) {
                    dispatch(pbActions.dispatchSceneData(sceneData[this.count]))
                    this.count = this.count + 1;
                } else {
                    this.count = 1;
                    clearInterval(this.interval)
                }
            }, 1000)
        }
    }

    getEventName = (asp) => {
        const { warnDetail, warnType } = asp;
        if (!warnType.length) {
            return "当前速度"
        } else if (warnType[0] === 'vsl_information') {
            return "识别限速"
        } else if (warnType[0] === "enter") {
            return "场景触发"
        } else if (warnType[0] === "fixed") {
            return "固定位置点"
        } else if (warnType[0] === "leave") {
            return "场景结束"
        } else if (warnType[0] === "end") {
            return "测试结束"
        }
    }

    getSpecialEvent = (warnDetail,warnType) => {
        const { acceleration, distance, speed } = warnDetail;
        
        if(warnType === 'speed_limit'){
          return`${distance?`距离限速标志 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'give_way'){
          return`${distance?`距离停车让行标志 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'traffic_lane'){
          return `${distance?`距离弯道 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'Crosswalk'){
          return `${distance?`距离人行横道线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'signal_lamp'){
          return `${distance?`距离停止线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'direction_lamp'){
          return `${distance?`距离停止线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'barricade'){
          return `${distance?`距离前方障碍物 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'error_action'){
          return `${distance?`距离前方障碍物 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }else if(warnType === 'pedestrian_cross_road'){
          return `${distance?`距离人行横道线 ${distance} m`:''} ${speed?`速度 ${speed} KM/H`:''} ${acceleration?`加速度 ${acceleration} m/s²`:''}`
        }
      }
    
      getEventDetail = (e) => {
        const { warnDetail, warnType } = e;
    
        if (warnType[0] === 'light') {
          return `${warnDetail['light'].hazard_signal ? ' 警示灯亮' : ''} ${warnDetail['light'].left_turn_signal ? '左转灯亮' : ''} ${warnDetail['light'].right_turn_signal ? '右转灯亮' : ''}`
        } else if (warnType[0] === 'speed') {
          return `速度为${warnDetail['speed'].speed} 加速度为${warnDetail['speed'].acceleration}`
        } else if (warnType[0] === 'glosa_info') {
          return `${eventEnMap[warnType[0]]} ${typeof warnDetail.glosa_info === 'string' && JSON.parse(warnDetail.glosa_info)[0] && JSON.parse(warnDetail.glosa_info)[0].advisory_speed ? JSON.parse(warnDetail.glosa_info)[0].advisory_speed : '0'}KM/H`
        } else if (warnType[0] === 'vsl_information') {
          return `${eventEnMap[warnType[0]]} ${typeof warnDetail.vsl_information === 'string' && JSON.parse(warnDetail.vsl_information).advisory_speed ? JSON.parse(warnDetail.vsl_information).advisory_speed : '0'} KM/H`
        } else if (warnType[0] === 'curve_speedlimit_information') {
          return `${eventEnMap[warnType[0]]} ${typeof warnDetail.curve_speedlimit_information && JSON.parse(warnDetail.curve_speedlimit_information).curve_speedlimit_value ? JSON.parse(warnDetail.curve_speedlimit_information).curve_speedlimit_value : '0'} KM/H`
        } else if (specialEventMap[warnType[0]]){ //如果是特殊事件
          return this.getSpecialEvent(warnDetail[warnType[0]],warnType[0])
        }
        else {
          return eventEnMap[warnType[0]]
        }
      }

      getNewEventDetail=(e)=>{
        const { warnDetail, warnType } = e;
        let str = '';
        for(let i=0;i<warnType.length;i++){
          if(i !== warnType.length-1)
            str = str + warnDetail[warnType[i]]+' \n  '
          else
            str = str + warnDetail[warnType[i]]
        }
        return str
      }

    getCarMenu = () => {
        const { allTestingVUT } = this.props

        return (
            <Menu onClick={this.onCarSelect}>
                {
                    allTestingVUT.length ? allTestingVUT.map((car, index) => <Menu.Item key={car}>{car}</Menu.Item>) : <Menu.Item disabled key="nocar">无</Menu.Item>
                }
            </Menu>
        )
    }

    sceneSelect = ({ recordSceneId, startTime, endTime, id, name }) => () => {
        const { playbackId, dispatch } = this.props
        dispatch(pbActions.clearData())
        dispatch(pbActions.getSceneData({ id: playbackId, testFieldId: QDTestFieldId, recordSceneId: recordSceneId }))

        this.setState({
            selectSceneId: recordSceneId,
            showProjectDrawer: false,
            playInfo: {
                startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
                title: `${id} - ${name}`,
                duration: `${moment.duration(endTime - startTime).asMinutes().toFixed(1)} min`
            }
        })
    }

    testItemMenuClick = ({ item, key, keyPath }) => {
        this.setState({
            selectProject: keyPath[1].split(",")[1],
            selectScene: keyPath[0].split(",")[1]
        })

        post('/testSite/scene/find', { id: keyPath[0].split(",")[0] }).then(res => {
            if (res.status === 200 && res.data.code === 200) {
                if (res.data.data.list && res.data.data.list.length) {
                    this.setState({
                        selectRules: res.data.data.list[0]
                    })
                }
            }
        })
    }

    onPasePlay = () => {
        this.setState({
            isPlay: false
        })
        clearInterval(this.interval)
    }

    onPlayClick = () => {
        const { dispatch, sceneData } = this.props
        const length = sceneData.length;
        clearInterval(this.interval)
        this.setState({
            isPlay: true
        })

        dispatch(pbActions.dispatchSceneData(sceneData[this.count]))
        this.count = this.count + 1;
        this.interval = setInterval(() => {
            if (this.count <= length - 1) {
                dispatch(pbActions.dispatchSceneData(sceneData[this.count]))
                this.count = this.count + 1;
            } else {
                this.count = 0;
                clearInterval(this.interval)
            }
        }, 1000)

    }

    render() {
        const { info, vutEvent, backToTable } = this.props;
        const { vtCameraId, inVutCameraId, vutObeId, vtObeId, vtId, vutId } = this.state.VUTInfo;
        const { scheme, selectSceneId, playInfo, isPlay } = this.state

        const menu = (
            <Menu onClick={this.testItemMenuClick} getPopupContainer={() => this.main.current}>
                {
                    this.state.testItemList.map(project => {
                        return (
                            <SubMenu key={`${project.id},${project.name}`} title={project.name}>
                                {project.scenes && project.scenes.length && project.scenes.map((scene, index) => {
                                    return (
                                        <Menu.Item key={`${scene.id},${scene.name}` || index}>{scene.name}</Menu.Item>
                                    )
                                })}
                            </SubMenu>
                        )
                    })
                }
            </Menu>
        );

        //console.log(info)

        return (
            <div ref={this.main} className={styles.main}>
                {/* <header className={styles.header}>场景测试实时监控 <button onClick={() => this.main.current.requestFullscreen()}>全屏</button>   </header> */}
                <FSHeader
                    backToTable={backToTable}
                    title='场景测试过程回放'
                >
                </FSHeader>
                <div className={styles.content}>
                    <div className={`${styles.contentChild} ${styles.leftContent}`}>
                        <div className={styles.leftTop}>
                            <div className={styles.cuCarTab}>

                                <div className={styles.carDetailCon}>
                                    <div className={`${styles.carDetail} ${styles.detailTab} ${styles.leftCorner}`}>
                                        待测车辆 - {vutId ? `${vutId}` : '--'}
                                    </div>
                                    <div className={`${styles.carDetail} ${styles.detailContent2}`}>
                                        <span className={`${styles.leftDetailTitle}`}>车载设备ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={vutObeId}>{vutObeId || '--'}</span>
                                    </div>
                                    <div className={`${styles.carDetail} ${styles.detailContent1}`}>
                                        <span className={`${styles.leftDetailTitle}`}>车载监控ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={inVutCameraId}>{inVutCameraId || '--'}</span>
                                    </div>
                                </div>

                                <div className={styles.graphCon}>
                                    {/* 仪表盘 */}
                                    <div className={styles.gaugeCon}>
                                        {<Gauge color={'rgba(2,187,240,1)'} speed={info && info.vutMoitor && info.vutMoitor.speed ? info.vutMoitor.speed : 0} title="实时速度"></Gauge>}
                                    </div>

                                    <div className={styles.speedHistoryChart}>
                                        <Chart speed={0} title="速度历史"></Chart>
                                    </div>

                                    <div className={styles.speedHistoryChart}>
                                        <AccBar title={true}></AccBar>
                                    </div>

                                    <div style={{ width: '100%', height: '25%' }}>
                                        <VutAccChart title="加速度历史"></VutAccChart>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bacCarTab}>
                                <div className={styles.carDetailCon}>
                                    <div className={`${styles.carDetail} ${styles.detailTab} ${styles.rightCorner}`}>
                                        背景车辆 - {vtId || "--"}
                                    </div>
                                    <div className={`${styles.carDetail} ${styles.detailContent2}`}>
                                        <span className={`${styles.leftDetailTitle}`}>车载设备ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={vtObeId}>{vtObeId || '--'}</span>
                                    </div>
                                    <div className={`${styles.carDetail} ${styles.detailContent1}`}>
                                        <span className={`${styles.leftDetailTitle}`}>车载监控ID</span><span className={`${styles.detailText} ${styles.leftDetailText}`} title={vtCameraId}>{vtCameraId || "--"}</span>
                                    </div>

                                </div>
                                {/* 仪表盘 */}
                                <div className={styles.graphCon}>
                                    <div className={styles.gaugeCon}>
                                        {<Gauge color={'rgba(255,193,67, 1)'} speed={info && info.vtMoitor && info.vtMoitor.speed ? info.vtMoitor.speed : 0}></Gauge>}
                                    </div>

                                    <div className={styles.speedHistoryChart}>
                                        <BcSpeedChart></BcSpeedChart>
                                    </div>

                                    <div className={styles.speedHistoryChart}>
                                        <BcAccBar></BcAccBar>
                                    </div>

                                    <div style={{ width: '100%', height: '25%' }}>
                                        <VtAccChart></VtAccChart>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.leftBottom}>
                            <div className={styles.yellowTitle}>当前路口信号灯</div>
                            <Signal></Signal>
                        </div>
                    </div>

                    <div className={`${styles.contentChild} ${styles.midContent}`}>
                        <div className={styles.mapCon}>
                            <div className={`${SiteStyles.topLine}`}></div>
                            <div className={`${SiteStyles.bottomLine}`}></div>
                            <div className={`${SiteStyles.leftLine}`}></div>
                            <div className={`${SiteStyles.rightLine}`}></div>
                            <Bmap process={this.state.process} />
                            {
                                JSON.stringify(playInfo) !== '{}' &&
                                <div className={styles.playBar}>
                                    {isPlay ?
                                        <Icon
                                            style={{ fontSize: 20, color: '#095CE5', cursor: 'pointer' }}
                                            type="pause-circle"
                                            onClick={this.onPasePlay}
                                        /> :
                                        <Icon
                                            style={{ fontSize: 20, color: '#095CE5', cursor: 'pointer' }}
                                            type="play-circle"
                                            onClick={this.onPlayClick}
                                        />
                                    }
                                    <div className={styles.schemeTitle} title={playInfo.title}>{playInfo.title}</div>
                                    <div className={styles.schemeTimeSelect} title={playInfo.startTime}>{playInfo.startTime}</div>
                                    <div className={styles.schemeTimeSelect} title={playInfo.endTime}>{playInfo.endTime}</div>
                                    <div className={styles.schemeTimeSelect} title={playInfo.duration}>总时长：{playInfo.duration}</div>
                                </div>
                            }
                        </div>

                        {/* <div className={styles.leftBottom}>
              <div className={styles.yellowTitle}>当前路口信号灯</div>
              <Signal></Signal>
            </div> */}

                        <div className={styles.OutervideoCon}>
                            <header className={styles.videoHeader}>
                                <span className={styles.videoHeaderText}>车载车内视频监控</span>
                                <span className={styles.videoHeaderText}>车载车外视频监控</span>
                            </header>
                            <div className={styles.outerVideoContent}>
                                <div className={styles.singleVideo} style={{ marginRight: 5 }}>
                                    {/* VUTInfo && VUTInfo.inVutCameraUrl && <VideoComponent 
                    key={1} 
                    //url={'https://flvopen.ys7.com:9188/openlive/428facb643144812bd72e4cf411c2560.flv'} 
                    url={VUTInfo.inVutCameraUrl}
                    videoClose={() => { }} 
                    resetCameraInfo={() => { }}>
                    </VideoComponent> */}
                                </div>
                                <div className={styles.singleVideo}>
                                    {/* VUTInfo && VUTInfo.outVutCameraUrl && <VideoComponent 
                    key={2} 
                    //url={'https://flvopen.ys7.com:9188/openlive/8f1b757457b8433d9e05d8941deff1ef.flv'} 
                    url={VUTInfo.outVutCameraUrl}
                    videoClose={() => { }} 
                    resetCameraInfo={() => { }}>
                    </VideoComponent> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.contentChild} ${styles.rightContent}`}>
                        <div className={styles.rightTabs}>
                            <Dropdown trigger={['click']} overlay={menu} getPopupContainer={() => this.main.current}>
                                <div style={{ cursor: 'pointer' }} className={`${styles.rightDetailTab} ${styles.leftCorner}`}>
                                    <span className={`${styles.detailText} ${styles.noWrapText} ${styles.tabText}`} title={this.state.selectProject || ''}>测试项目：{this.state.selectProject || '--'}</span>
                                </div>
                            </Dropdown>
                            <div className={`${styles.rightDetailTab} ${styles.rightCorner}`}>
                                <span className={`${styles.detailText} ${styles.noWrapText}  ${styles.tabText}`} title={this.state.selectScene || ''}>场景测试：{this.state.selectScene || '--'}</span>
                            </div>
                        </div>

                        <div className={styles.rightTwoCon}>
                            <div className={styles.testRulesCon}>
                                <div className={styles.yellowTitle}>场景测试规程列表</div>
                                <div style={{ height: 'calc(100% - 56px)', width: '100%', display: 'flex' }}>
                                    <div className={styles.rulesLeft}>
                                        {/* <div style={{ height: '18%', textAlign: 'center' }} className={styles.detailContent2}>
                      <span className={`${styles.detailTitle}`}>测试项目：</span>
                    </div>
                    <div style={{ height: '18%', textAlign: 'center' }} className={styles.detailContent1}>
                      <span className={`${styles.detailTitle}`}>测试场景：</span>
                    </div> */}
                                        <div style={{ height: '50%', textAlign: 'center' }} className={styles.detailContent2}>
                                            <span className={`${styles.detailTitle}`}>测试方法：</span>
                                        </div>
                                        <div style={{ height: '50%', textAlign: 'center' }} className={styles.detailContent1}>
                                            <span className={`${styles.detailTitle}`}>测试要求：</span>
                                        </div>
                                    </div>
                                    <div className={styles.rulesRight}>
                                        {/* <div style={{ height: '18%', textAlign: 'left', fontSize: 14, backgroundColor: 'rgba(3,221,253,0.3)', padding: '12px 14px' }} className={styles.detailText}>
                      <span className={styles.detailText}>交通标志与标线</span>
                    </div>
                    <div style={{ height: '18%', textAlign: 'left', fontSize: 14, backgroundColor: 'rgba(3,221,253,0.5)', padding: '12px 14px' }} className={styles.detailText}>
                      <span className={styles.detailText}>限速标志</span>
                    </div> */}
                                        <div style={{ backgroundColor: 'rgba(3,221,253,0.3)' }} className={styles.detailTextCon}>
                                            {/* <span className={styles.detailText}>1.距离限速标志100m前到达限速标志所示速度的1.2倍</span> <br />
                      <span className={styles.detailText}>2.匀速驶向限速标志</span> */}
                                            <span className={styles.detailText}>
                                                {
                                                    this.state.selectRules && this.state.selectRules.testMethod
                                                }
                                            </span>
                                        </div>
                                        <div style={{ backgroundColor: 'rgba(3,221,253,0.5)' }} className={styles.detailTextCon}>
                                            {/* <span className={styles.detailText}>到达限速标志时，车速应不高于限速标志所示速度</span> */}
                                            {
                                                this.state.selectRules && this.state.selectRules.requirement.split("；").map((rule, index) => {
                                                    return (
                                                        <div key={index} className={styles.detailText}>
                                                            {rule}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.RollingeventsCon}>
                                <div className={styles.yellowTitle}>
                                    场景测试实时事件
                                </div>
                                <div className={styles.eventDetailCon}>
                                    <div className={styles.listHeader}>
                                        <span className={`${styles.eventListText} ${styles.evnetHeaderDetail}`}>事件说明</span>
                                        <span className={`${styles.eventListText} ${styles.evnetHeaderTime}`}>事件时间</span>
                                    </div>

                                    <div ref={this.rollCon} className={styles.eventListCon}>

                                        {/* <span className={`${styles.detailTitle} ${styles.evnetHeaderName}`}>事件名称</span>
                      <span className={`${styles.detailText} ${styles.evnetHeaderDetail}`}>事件说明</span>
                      <span className={`${styles.eventTimeText} ${styles.evnetHeaderTime}`}></span> */}
                                        {
                                            vutEvent.map((event, index) =>
                                                event ? event.warnType && event.warnType.length>0 && <div key={index} className={styles.detailConLight}>
                                                    <span className={`${styles.detailText} ${styles.evnetHeaderDetail}`} title={this.getNewEventDetail(event)} >{this.getNewEventDetail(event)}</span>
                                                    <span className={`${styles.eventTimeText} ${styles.evnetHeaderTime}`} title={event.time ? moment(event.time).format('HH:mm:ss') : ''}>{event.time ? moment(event.time).format('HH:mm:ss') : ''}</span>
                                                </div> : null
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={styles.rightTria}
                        onClick={() => this.setState({
                            showProjectDrawer: !this.state.showProjectDrawer
                        })}
                        style={{ right: this.state.showProjectDrawer ? '30%' : 0 }}
                    >
                    </div>

                    <div className={styles.projectPane} style={{ width: this.state.showProjectDrawer ? '30%' : 0 }}>
                        <header className={styles.projectPaneHeader}>
                            <span className={styles.projectHeaderTitle}>单场景回放</span>
                            <span className={styles.projectHeaderSubTitle}>请从测试方案中选择某个场景</span>
                        </header>
                        {scheme.length && <section className={styles.projectPaneContent}>
                            <header className={styles.schemeHeader}>
                                <div className={styles.schemeLeftCol}>
                                    <Icon style={{ fontSize: 18, color: 'gray', margin: '0 10px' }} type="folder" />
                                    <div className={styles.schemeName}>{scheme[0].name}</div>
                                </div>
                                <div className={`${styles.schemeMidRightCol} ${styles.schemeHeaderText}`}>
                                    场景开始时间
                                </div>
                                <div className={`${styles.schemeMidRightCol} ${styles.schemeHeaderText}`}>
                                    场景结束时间
                                </div>
                            </header>
                            <div className={styles.schemeContent}>
                                {
                                    scheme[0].projects && scheme[0].projects.length && scheme[0].projects.map((prj, index) => {
                                        return (
                                            <div key={index}>
                                                <div className={styles.projectTitle}>{prj.id} - {prj.name}</div>
                                                {prj.scenes && prj.scenes.length && prj.scenes.map((sc, index) => {
                                                    return (
                                                        <div
                                                            onClick={this.sceneSelect(sc)}
                                                            key={index}
                                                            className={selectSceneId === sc.recordSceneId ? styles.scenesConSelect : styles.scenesConUnSelect}
                                                            title={`${sc.id} - ${sc.name} ${sc.startTime ? moment(sc.startTime).format('YYYY-MM-DD HH:mm:ss') : '无起始时间'} ${sc.endTime ? moment(sc.endTime).format('YYYY-MM-DD HH:mm:ss') : "无结束时间"}`}
                                                        >
                                                            <div className={`${styles.schemeLeftCol} ${styles.schemeTitle}`}>
                                                                <div className={selectSceneId === sc.recordSceneId ? styles.schemeCircleSelect : styles.schemeCircleUnselect}></div>
                                                                <div style={{ maxWidth: '90%' }}>{sc.id} - {sc.name}</div>
                                                            </div>
                                                            <div className={`${styles.schemeMidRightCol} ${selectSceneId === sc.recordSceneId ? styles.schemeTimeSelect : styles.schemeTimeUnSelect}`}>
                                                                {sc.startTime ? moment(sc.startTime).format('YYYY-MM-DD HH:mm:ss') : '无起始时间'}
                                                            </div>
                                                            <div className={`${styles.schemeMidRightCol} ${selectSceneId === sc.recordSceneId ? styles.schemeTimeSelect : styles.schemeTimeUnSelect}`}>
                                                                {sc.endTime ? moment(sc.endTime).format('YYYY-MM-DD HH:mm:ss') : "无结束时间"}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>}


                    </div>
                </div>

                {this.state.showProjectDrawer && <div onClick={() => { this.setState({ showProjectDrawer: false }) }} className={styles.modal}>

                </div>}

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { siteMonitorCamList, allTestingVUT, VUTInfo, realTimeData, vutEvent } = state.realTime;
    const { siteInfo, markerInfo } = state.siteConfig;
    const { veh, vt, info } = realTimeData
    const { sceneData } = state.playback

    return {
        siteMonitorCamList,
        allTestingVUT,
        siteInfo,
        markerInfo,
        VUTInfo,
        realTimeData,
        veh,
        vt,
        info,
        vutEvent,
        sceneData
    }
}

export default connect(mapStateToProps)(SceneMonitor)