import React, { Component } from 'react'
import { connect } from 'react-redux';
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import _ from 'lodash';
import styles from './styles.module.css'

class AttBarChart extends Component {
    constructor(props) {
        super(props);
        this.chartElement = React.createRef()
    }

    state = {
        showNoData: false
    }

    componentDidMount() {
        /* if(this.props.range) */
        //this.echart = this.drawChart({ data: [] })
        //console.log(1)
        this.mydata = [1, 2, 3]
        this.resizeEventHdlr = window.addEventListener('resize', this.onResize);
        this.echart = this.drawChart({ data: [] })
        setTimeout(() => {
            this.echart.resize();
        }, 0)

        /* setInterval(() => {
            this.mydata.push(Math.random() * 10)
            //console.log(this.mydata)
            this.echart.setOption({
                series: [{
                    data: this.mydata
                }]
            })
        }, 2000) */
    }

    onResize = _.throttle(() => {
        //console.log('resize');
        this.echart.resize();
    }, 500)

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps) {
        const { vutAccHistory } = nextProps
        // const { vutMoitor } = info
        // const historySpeed = info && info.vutMoitor && info.vutMoitor.historySpeed ? info.vutMoitor.historySpeed : null
        if (vutAccHistory) {

            this.echart.setOption({
                series: [{
                    data: vutAccHistory
                }]
            })
        }
    }



    drawChart = ({ data }) => {
        // 基于准备好的dom，初始化echarts实例
        //console.log(data,'timealter')

        const myChart = echarts.init(this.chartElement.current);
        myChart.setOption({
            //title: { text: 'ECharts 入门示例' },
            grid: {
                top: 20,
                left: '5px',
                right: 0,
                height: '75%',
                width: '100%'
            },
            xAxis: {
                //data: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"], 
                type: 'category',
                show: false,
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                splitLine: {
                    show: false
                },
                show: false
            },
            series: [{

                type: 'line',
                data: data,
                //barWidth: '6px',
                areaStyle: {
                    normal: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'rgba(3,221,253,0.3)'
                            }, {
                                offset: 0.5, color: 'rgba(3,221,253,0.1)'
                            }, {
                                offset: 1, color: 'rgba(3,221,253,0)'
                            }]
                        }
                    }
                },
                itemStyle: {
                    color: '#03DDFD'
                },
                symbol: 'none'
            }],
            /*  dataZoom: [
                 {
                     xAxisIndex: [0],
                     orient: 'horizontal',
                     realtime: true,
                     type: 'inside',
                 }
             ] */
        });

        // console.log(myChart)
        return myChart;
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                {this.props.title && <div className={styles.canvasNormalTitle}>{this.props.title}</div>}
                <div id={styles.timeAlterChart} ref={this.chartElement} ></div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { vutAccHistory } = state.realTime;

    return {
        vutAccHistory
    }
}

export default connect(mapStateToProps)(AttBarChart)