import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import styles from './header.module.css'
import { Avatar, Icon, Dropdown,Menu } from 'antd';



@withRouter
export default class Header extends Component {
    onClick = ({item, key, keyPath}) => {
        if(key === "0"){
           localStorage.clear();
           this.props.history.replace('/login')
        }
    }
    
    render() {
        const name = localStorage.name
        const menu = (
            <Menu onClick={this.onClick}>
                <Menu.Item key="0">
                    <span>登出</span>
                </Menu.Item>
            </Menu>
        );

        return (
            <div className={styles.main}>
                <div className={styles.title}>
                    {this.props.title}
                </div>

                <div className={styles.logInfo}>
                    <div className={styles.avatar}>
                        <Avatar size={40} icon="user" />
                    </div>
                    <div className={styles.user}>
                        <span className={styles.userName} title={name}>{name}</span>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Icon style={{ color: '#888888', marginTop: 4, cursor: 'pointer' }} type="caret-down" />
                        </Dropdown>
                    </div>

                </div>
            </div>
        )
    }
}