import * as types from './actionTypes'

export const siteInfo = (state = {}, action) => {
    switch (action.type){
        case types.GET_SITE_INFO:
        return action.data;

        case types.CLEAR_SITE_INFO:
        return {};

        default:
        return state;
    }
}

export const markerInfo = (state = {},action) => {
    switch(action.type){
        case types.GET_ALL_MARKER_INFO:
        return action.data;

        case types.CLEAR_ALL_MARKER_INFO:
        return {};

        default:
        return state;
    }
}