import { post } from "../../api/request.js";
import * as types from './actionTypes';
import * as realTimeTypes from '../realTimeMonitor/actionTypes'

//获取场景数据
export const getSceneData = ({ id, testFieldId, recordSceneId }) => dispatch => {
    post('/testSite/history/getHistoryScene', { id, testFieldId, recordSceneId }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
            dispatch({
                type: types.GET_SCENE_DATA,
                data: res.data.data.map(record => JSON.parse(record))
            })
        }
    })
}

//分发伪实时数据
export const dispatchSceneData = (data) => dispatch => {
    const vutSpeed = data && data.info && data.info.vutMoitor && data.info.vutMoitor.speed !== undefined && data.info.vutMoitor.speed !== null?data.info.vutMoitor.speed:null;
    const vutAcc = data && data.info && data.info.vutMoitor && data.info.vutMoitor.acceleration !== undefined && data.info.vutMoitor.acceleration !== null?data.info.vutMoitor.acceleration:null;
    const vtSpeed = data && data.info && data.info.vtMoitor && data.info.vtMoitor.speed !== undefined && data.info.vtMoitor.speed !== null?data.info.vtMoitor.speed:null;
    const vtAcc = data && data.info && data.info.vtMoitor && data.info.vtMoitor.acceleration !== undefined && data.info.vtMoitor.acceleration !== null?data.info.vtMoitor.acceleration:null;
    const event = data && data.veh && data.veh.vutData && data.veh.vutData.warnDetail && data.veh.vutData.warnType && data.veh.vutData.utcTime ?
        {
            warnType: data.veh.vutData.warnType,
            warnDetail: data.veh.vutData.warnDetail,
            time: data.veh.vutData.utcTime * 1000,
            /* speed:vutSpeed,
            acc:vutAcc */
        } :
        null

    if(vutSpeed !== null){
        dispatch({
            type: realTimeTypes.UPDATE_VUT_HISTORY,
            data: vutSpeed
        })
    }

    if(vutAcc !== null){
        dispatch({
            type: realTimeTypes.UPDATE_VUT_ACC_HISTORY,
            data: vutAcc
        })
    }

    if(vtSpeed !== null){
        dispatch({
            type: realTimeTypes.UPDATE_VT_HISTORY,
            data: vtSpeed
        })    
    }

    if(vtAcc !== null){
        dispatch({
            type: realTimeTypes.UPDATE_VT_ACC_HISTORY,
            data: vtAcc
        })
    }
    if(data){
      dispatch({
        type: realTimeTypes.UPDATE_VUT_EVENT,
        data: event
      })

      dispatch({
        type: realTimeTypes.GET_REALTIME_DATA,
        data: data
      })
    }

} 

//清除场景数据和伪实时数据
export const clearData = () => dispatch => {
    dispatch({
        type:types.CLEAR_SCENE_DATA
    })

    dispatch({
        type:realTimeTypes.CLEAR_ALL_REALTIME_DATA
    })
}

//请示伪实时数据
export const clearFakRealData = () => dispatch => {
    dispatch({
        type:realTimeTypes.CLEAR_ALL_REALTIME_DATA
    })
}