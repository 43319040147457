import React from 'react'
import {Row,Col,Form, Input, Button,Select,Radio,Switch,Tabs,Upload, Icon,Modal,message,InputNumber    } from 'antd'
import MarkMap from './markMap'
import styles from './style.module.css'
import scene from './img/scene.png'
import faker from "faker";
import {sceneInfo} from '../../../api/sceneInfo'
import { bd09togcj02, gcj02tobd09, wgs84togcj02, gcj02towgs84 } from '../../../api/latLngTransform';
import {QDTestFieldId} from '../../../view/public/testFieldID'
import { connect } from "react-redux";
import * as actions from "../../../reducer/siteConfig/actions";

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 8},
  wrapperCol: { span: 8 },
};

class TestSceneDialog extends React.Component{
  constructor(props){
    super(props);
    this.state={
      mapShow:true,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      allProjectList:[],
      mapPoint:null,
      activeKey:"2",
      imageIdList:[],
      distanceItem:[],
      biaoShiList:[],
      initMarkList:[],
      markList:[]
      /*markList:[{id:1,distanceList:[50,100,150]},{id:2,distanceList:[50,100,150]}]*/
    }
    this.leftForm = React.createRef();
  }


  componentDidMount(){

    const { testField } = this.props
    if(!testField){
      const { dispatch } = this.props;
      dispatch(actions.getSiteInfo(QDTestFieldId));
    }
    if(this.props.operaType === "create"){

    }else{

          // console.log(this.props.sceneInfo.coreLon, this.props.sceneInfo.coreLat,"硬件左边")
          const gcjLatLng = wgs84togcj02(this.props.sceneInfo.coreLon, this.props.sceneInfo.coreLat)
          const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
          // console.log(bdLatLngFromGCJ.lng, bdLatLngFromGCJ.lat,"百度坐标")
           this.setState({mapPoint:{lng:bdLatLngFromGCJ.lng,lat:bdLatLngFromGCJ.lat},mapShow:true})
          // console.log(this.state.mapShow,"地图的显示情况")
          this.props.changeDialogWidth("60%")

        const {sceneInfo} = this.props
        let tempArray = []
        let oldImageIdList = []
        for(let i=0;i<sceneInfo.imageIdList.length;i++){
          tempArray.push({uid:faker.name.findName(),url:`/testSite/scene/getImage?imageId=${sceneInfo.imageIdList[i]}`})
          oldImageIdList.push(sceneInfo.imageIdList[i])
        }
        this.setState({fileList:tempArray,imageIdList:oldImageIdList,markList:sceneInfo.sceneMarkList,initMarkList:sceneInfo.sceneMarkList})

    }
    this.getMarkInfoByTestFieldId({"ID":QDTestFieldId})
    this.getAllProjectList()
    console.log(this.leftForm,"1222")

  }

  getMarkInfoByTestFieldId=(data)=>{
    sceneInfo.getMarkByTestFieldId(data).then(res=>{
      if(res.data.code === 200){
        this.setState({biaoShiList:res.data.data})
      }
    })
  }

  getAllProjectList=()=>{
    sceneInfo.getProjectList().then(res=>{
      if(res.data.code === 200){
          this.setState({allProjectList:res.data.data.list})
      }
    })
  }

  handleCancel = () => this.setState({ previewVisible: false })

  onChange=(checked)=> {
    this.setState({mapShow:checked})
    if(checked){
      this.props.changeDialogWidth("60%")
    }else{
      this.props.changeDialogWidth("60%")
    }

    //console.log(`switch to ${checked}`);
  }

  changeLongitudeHandle=(e)=>{
    const value = e.target.value;
    // console.log(value,"经度")
    if(value && this.props.form.getFieldValue("handLatitude")){
      const gcjLatLng = wgs84togcj02(parseFloat(value), parseFloat(this.props.form.getFieldValue("handLatitude")))
      const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
      this.setState({mapPoint:{lng:bdLatLngFromGCJ.lng,lat:bdLatLngFromGCJ.lat}})

    }
    // this.props.form.getFieldValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    // console.log(e,"eeeeeeee")
  }

  changeLatitudeHandle=(e)=>{
    const value = e.target.value;
    // console.log(value,"纬度")
    if(this.props.form.getFieldValue("handLongitude") && value){
      const gcjLatLng = wgs84togcj02(parseFloat(this.props.form.getFieldValue("handLongitude")),parseFloat(value))
      const bdLatLngFromGCJ= gcj02tobd09(gcjLatLng.lng, gcjLatLng.lat)
      this.setState({mapPoint:{lng:bdLatLngFromGCJ.lng,lat:bdLatLngFromGCJ.lat}})

    }
    // this.props.form.getFieldValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    // console.log(e,"eeeeeeee")
  }




  callback=(key)=> {
    this.setState({activeKey:key})
    //console.log(key);
  }

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }

  handleRemove =(file)=>{
    let idstr = null
    if(file.response)
        idstr = file.response.data.imageIdList[0]
    else
        idstr  = file.url.split("=")[1]
    sceneInfo.sceneDelImage({id:idstr}).then(res=>{
      if(res.data.code === 200){
        message.success('删除成功!')
        let tempArray = []
        for(let i=0;i<this.state.imageIdList.length;i++){
            if(this.state.imageIdList[i] !== idstr){
                tempArray.push(this.state.imageIdList[i])
            }
        }
        this.setState({imageIdList:tempArray})
      }else{
        message.error('删除失败!')
      }
    })

  }


  handleCancel = () => this.setState({ previewVisible: false })



  handleChange = ({ fileList,event,file }) => {

    if(file.status === 'done'){
      this.setState({imageIdList:this.state.imageIdList.concat(file.response.data.imageIdList)})
      message.success("上传成功!")

    }
    // console.log(fileList,"fileList")
    this.setState({ fileList })

  }

  getMapPoint=(point)=>{
    const gcjLatLng = bd09togcj02(point.lng, point.lat)
    const wgsLatLngFromBD = gcj02towgs84(gcjLatLng.lng, gcjLatLng.lat)
    // console.log(wgsLatLngFromBD.lng, wgsLatLngFromBD.lat,"硬件坐标")
    this.props.form.setFieldsValue({"handLongitude":wgsLatLngFromBD.lng,handLatitude:wgsLatLngFromBD.lat})
    this.setState({mapPoint:point})
  }

  changeMark=(value,option)=>{
    //console.log(`selected ${value}`,option);
    const {biaoShiList,markList,initMarkList} = this.state
    let tempArray = []
    for(let i=0;i<option.length;i++){
      for(let j=0;j<biaoShiList.length;j++){
        if(biaoShiList[j].markNo === option[i].props.value){
          tempArray.push({markNo:option[i].props.value,markName:option[i].props.children,distanceList:[null],imgUrl:biaoShiList[j].imgUrl,latitude:biaoShiList[j].latitude,longitude:biaoShiList[j].longitude})
        }
      }
    }
    if(this.props.operaType === 'update'){
      for(let a=0;a<initMarkList.length;a++){
        for(let b=0;b<tempArray.length;b++){
          if(tempArray[b].markNo === initMarkList[a].markNo){
            tempArray[b].distanceList = initMarkList[a].distanceList
          }
        }
      }
    }
    this.setState({markList:tempArray})


  }

  removeDistance=(id,index)=>{
    /* if(index === 0){
      return
    } */
    const {markList} = this.state
    let tempArray = []
    for(let i=0;i<markList.length;i++){
      let tempDistanceList =[]
      for(let j=0;j<markList[i].distanceList.length;j++){
        if(markList[i].markNo== id && j === index){
              continue;
        }else{
          tempDistanceList.push(markList[i].distanceList[j])
        }
      }
      tempArray.push({markNo:markList[i].markNo,markName:markList[i].markName,distanceList:tempDistanceList})
    }
    this.setState({markList:tempArray})

  }

  addDistance=(id)=>{
    const {markList} = this.state
    let tempArray = []
    for(let i=0;i<markList.length;i++){
      if(markList[i].markNo === id){
        tempArray.push({markNo:markList[i].markNo,markName:markList[i].markName,distanceList:markList[i].distanceList.concat(null)})
      }else{
        tempArray.push({markNo:markList[i].markNo,markName:markList[i].markName,distanceList:markList[i].distanceList})
      }
    }
    this.setState({markList:tempArray})
  }

  createSceneInfo=()=>{
    let that = this

    this.props.form.validateFields((err, values) => {
      /* if(err && err.description){
        that.setState({activeKey:"1"})
        return
      } */
      if(err && err.testMethod){
        that.setState({activeKey:"2"})
        return
      }
      if(err && err.requirement){
        that.setState({activeKey:"3"})
        return
      }

      if (!err) {
         console.log('Received values of form: ', values);

        let data =null
        let tempArray = []
        if(values.mark){
          Object.keys(values).forEach(function(key,i,v){
            values.mark.map(item=>{
              if((item  === key) && values[key])
                tempArray.push({markNo:key,distanceList:values[key]})
            })

          })
        }

        if(this.props.operaType === "create"){
          data ={name:values.name,projectId:values.projectId,testMethod:values.testMethod,requirement:values.requirement,coreLat:values.handLatitude,coreLon:values.handLongitude}
          if(values.backgroundVehicle || values.backgroundVehicle === 0){
            data =Object.assign({},data,{backgroundVehicle:values.backgroundVehicle})
          }
          if(values.mark && values.mark.length>0){
            data =Object.assign({},data,{sceneMarkList:tempArray})
          }
          // data.imageIdList=this.state.imageIdList
          sceneInfo.addScene(data).then(res=>{
            if(res.data.code === 200){
              message.success("添加成功！")
              this.props.changModalVisible(false)
            }else{
              message.error(res.data.msg)
            }
          })
        }else{
          data ={id:this.props.sceneInfo.id,name:values.name,projectId:values.projectId,testMethod:values.testMethod,requirement:values.requirement,coreLat:values.handLatitude,coreLon:values.handLongitude}
          if(values.backgroundVehicle || values.backgroundVehicle === 0){
            data =Object.assign({},data,{backgroundVehicle:values.backgroundVehicle})
          }
          if(values.mark && values.mark.length>0){
            data =Object.assign({},data,{sceneMarkList:tempArray})
          }

          // data.imageIdList=this.state.imageIdList
          sceneInfo.editSceneInfo(data).then(res=>{
            if(res.data.code === 200){
              message.success("编辑成功！")
              this.props.changModalVisible(false)
              setTimeout(function(){
                that.props.updateSceneInfoById(that.props.sceneInfo.id)
              },300)

            }else{
              message.error(res.data.msg)
            }
          })

        }

      }
    });
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const {operaType,sceneInfo,testField} = this.props
    // console.log(sceneInfo,"sceneInfo")
    const prefixLongitudeSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">东经</Option>
        <Option value="87">西经</Option>
      </Select>
    );
    const prefixLatitudeSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">北纬</Option>
        <Option value="87">南纬</Option>
      </Select>
    );


    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div style={{display:'inline-block'}}>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (<div  >
      <Row gutter={20}>

          <Col span={12} refs={this.leftForm} style={{display:'table'}}>
            <Row gutter={20} style={{display:'table-cell'}}>
              <Col span={12}>

                <Form.Item  label="场景名称">
                  {getFieldDecorator('name', {
                    initialValue: operaType === "update"?sceneInfo.name:null,
                    rules: [{
                      required: true,
                      message: '请输入场景名称',
                    }],
                  })(
                    <Input placeholder="请输入场景名称" />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item  label="所属项目">
                  {getFieldDecorator('projectId', {
                    initialValue: operaType === "update"?sceneInfo.projectId:undefined,
                    rules: [{
                      required: true,
                      message: '请选择所属项目',
                    }],
                  })(
                    <Select placeholder="请选择所属项目" style={{width:'100%'}}>
                      {
                        this.state.allProjectList.map((v)=>{
                          return <Option value={v.id} key={faker.name.findName()}>{v.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>


              <Col span={24}>
                <div className={styles.sceneTitleCon}>
                  <Row >
                    <Col span={6} >
                      <img src={scene}></img>场景标记
                    </Col>
                    {/* <Col span={18}>
                      <Form.Item
                        label="是否在地图上打点："
                        {...formItemLayout}
                      >
                        {getFieldDecorator('sceneMarkWay',{initialValue: operaType === "update" && sceneInfo.sceneMarkWay?sceneInfo.sceneMarkWay :null})(
                          <Switch  onChange={this.onChange} defaultChecked={operaType === "update" && sceneInfo.sceneMarkWay && sceneInfo.sceneMarkWay === 1 ?true:false}/>
                        )}
                      </Form.Item>
                    </Col> */}
                  </Row>
                </div>
              </Col>

              <Col span={24}>
                <Row gutter={20}>
                  <Tabs  style={{padding:'0px !important'}}>
                    <TabPane tab="地图标记" key="1" >
                      {this.state.mapPoint?<Col span={12}>
                        <Form.Item label="地图经度" {...formItemLayout}>
                          <span className="ant-form-text">{this.state.mapPoint.lng}</span>
                        </Form.Item>
                      </Col>:<div style={{textAlign:'center',height:'40px',lineHeight:'40px'}}>请在地图上打点</div>}
                      {this.state.mapPoint?<Col span={12}>
                        <Form.Item label="地图纬度" {...formItemLayout}>
                          <span className="ant-form-text">{this.state.mapPoint.lat}</span>
                        </Form.Item>
                      </Col>:null}
                    </TabPane>
                    <TabPane tab="输入坐标" key="2" >
                      <Col span={12}>
                        <Form.Item
                          label="硬件经度"
                        >
                          {getFieldDecorator('handLongitude', {
                            initialValue: operaType === "update" ?sceneInfo.coreLon:null,
                            rules: [{ required: true, message: '请输入硬件经度!' },{ pattern: /^-?(((\d|[1-9]\d|1[0-7]\d|0)\.\d{0,16})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,16}|180)$/, message: '格式不对'}],
                          })(
                            <Input  style={{ width: '100%' }}  placeholder="请输入硬件经度" onChange={this.changeLongitudeHandle}/>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="硬件纬度">
                          {getFieldDecorator('handLatitude', {
                            initialValue: operaType === "update" ?sceneInfo.coreLat:null,
                            rules: [{ required: true, message: '请输入硬件纬度!' },{ pattern: /^-?([0-8]?\d{1}\.\d{0,16}|90\.0{0,16}|[0-8]?\d{1}|90)$/, message: '格式不对' }],
                          })(
                            <Input  style={{ width: '100%' }} placeholder="请输入硬件纬度" onChange={this.changeLatitudeHandle} />
                          )}
                        </Form.Item>
                      </Col>
                    </TabPane>

                  </Tabs>
                </Row>

              </Col>


              <Col span={24}>
                <div className={styles.scene}>
                    <Tabs defaultActiveKey={this.state.activeKey} activeKey={this.state.activeKey}  onChange={this.callback} style={{textAlign:'center',padding:'0px !important'}}>
                      {/* <TabPane tab="测试场景" key="1" className={styles.sceneFillCon}>
                        <Col span={12}>
                          <Form.Item  >
                            {getFieldDecorator('description', {
                              initialValue: operaType === "update"?sceneInfo.description:null,
                              rules: [{
                                required: true,
                                message: '请输入测试场景描述',
                              }],
                            })(
                              <TextArea rows={4} className={styles.sceneTextArea} placeholder="请输入测试场景描述"/>
                            )}
                          </Form.Item>
                          </Col>
                        <Col span={12} >
                          <Form.Item  >
                            {getFieldDecorator('img', {
                              rules: [{
                                required: false,
                              }],
                            })(
                              <Upload
                                name="image"
                                action="/testSite/scene/uploadImage"
                                listType="picture-card"
                                multiple={true}
                                fileList={this.state.fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                onRemove={this.handleRemove}
                              >
                                {fileList.length >= 3 ? null : uploadButton}
                              </Upload>
                            )}
                          </Form.Item>
                        </Col>
                      </TabPane> */}
                      <TabPane tab="测试方法" key="2" className={styles.sceneFillCon}>
                        <Col span={24}>
                          <Form.Item  >
                            {getFieldDecorator('testMethod', {
                              initialValue: operaType === "update"?sceneInfo.testMethod:null,
                              rules: [{
                                required: true,
                                message: '请输入测试方法',
                              }],
                            })(
                              <TextArea rows={4} className={styles.sceneTextArea} placeholder="请输入测试场景描述"/>
                            )}
                          </Form.Item>
                        </Col>
                      </TabPane>
                      <TabPane tab="测试要求" key="3" className={styles.sceneFillCon}>
                        <Col span={24}>
                          <Form.Item  >
                            {getFieldDecorator('requirement', {
                              initialValue: operaType === "update"?sceneInfo.requirement:null,
                              rules: [{
                                required: true,
                                message: '请输入测试要求',
                              }],
                            })(
                              <TextArea rows={4} className={styles.sceneTextArea} placeholder="请输入测试场景描述"/>
                            )}
                          </Form.Item>
                        </Col>
                      </TabPane>
                    </Tabs>
                </div>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="背景车辆"
                >
                  {getFieldDecorator('backgroundVehicle',{
                    initialValue: operaType === "update"?sceneInfo.backgroundVehicle:null,
                  })(
                    <Radio.Group>
                      <Radio.Button value={1}>需要</Radio.Button>
                      <Radio.Button value={0}>不需要</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="标识"
                >
                  {getFieldDecorator('mark',{
                    initialValue: operaType === 'update'?sceneInfo.sceneMarkList.map(v=>{return v.markNo}):undefined,
                    rules: [{
                      required: false,
                      message: '请选择场景绑定的标识',
                    }],
                  })(
                    <Select placeholder="请选择所属标识" style={{width:'100%'}} mode="multiple" onChange={this.changeMark} >
                      {this.state.biaoShiList.map((v)=>{
                        return <Option value={v.markNo} key={v.markNo}>{v.markName}</Option>
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>

        {testField  &&  <Col span={12} >
          <div >
              <MarkMap   testFieldInfo={testField}  mapPoint={this.state.mapPoint} markList={this.state.markList} getMapPoint={this.getMapPoint} sceneName={this.props.form.getFieldValue('name')}></MarkMap>
          </div>
        </Col>}


        <div style={{clear:'both'}}>
        {this.state.markList.map((v,i)=>{
          return (
            <span key={v.markNo}><Col span={12} >
              <span style={{margin:'20px 0px',display:'inline-block'}}>标识：{v.markName}(维护距离：米)</span>
              {
                v.distanceList.map((distance,index)=>{
                  return (<Form.Item key={v.markNo+' '+index}>
                    {getFieldDecorator(`${v.markNo}[${index}]`,{
                      initialValue: distance,
                      rules: [{
                        required: true,
                        message: '不能为空',
                      }],
                    })(
                      <InputNumber min={-100000} max={100000} placeholder="请输入距离" style={{ width: '60%', marginRight: 8 }} />
                    )}
                    <Icon
                      className="dynamic-delete-button"
                      type="minus-circle-o"
                      onClick={()=>this.removeDistance(v.markNo,index)}
                    />
                  </Form.Item>)

                })
              }
              {v.distanceList.length <3 && <Form.Item >
                <Button type="dashed" style={{ width: '60%' }} onClick={()=>this.addDistance(v.markNo)}>
                  <Icon type="plus" /> 新增
                </Button>
              </Form.Item>}

            </Col>{i%2 === 0?null:<div style={{clear:'both'}}></div>}</span>
          )
        })}
        </div>

      </Row>
      <Row>
        <Col span={12} className={styles.sceneCancelBtn} onClick={()=>{this.props.changModalVisible(false)}}><Button>取消</Button></Col>
        <Col span={12} className={styles.sceneSubmitBtn}><Button  className={styles.btnStyle} onClick={this.createSceneInfo} >保存</Button></Col>
      </Row>

      <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
      </Modal>

    </div>)
  }
}

const WrapTestSceneDialog = Form.create()(TestSceneDialog)
const mapStateToProps = (state) => {
  const { siteInfo } = state.siteConfig;

  return {
    testField: siteInfo.testField
  }
}

export default connect(mapStateToProps)(WrapTestSceneDialog)
// export default WrapTestSceneDialog