import axios from "axios"
// import qs from "qs"
// import { message } from "antd";

let baseURL = "https://www.huali-cloud.com"

// axios.defaults.timeout = 100000;

if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://localhost:3000';
} else {
   // baseUrl = 'http://localhost:3000';
}

axios.defaults.baseURL = baseURL;//请求地址设置
// 配置请求拦截
axios.interceptors.request.use(config => {
    // config.setHeaders([
    //   // 在这里设置请求头与携带token信息
    // ]);
    let token = localStorage.getItem('token');
    //const token = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhZG1pbiJ9.GksQ5gUhylu1pSIESL51CqMlIYDJmLjSVvQeeC5gods'
    // in prod default to api host in config
    if (process.env.NODE_ENV === 'production') {
      config.baseURL = process.env.API_HOST
    }
    if(token){
      config.headers.Authorization = token;
      // this.$route.push({'path':'/index/accountmanagement'})
    }else{
    }
    return config;
})

// 添加响应拦截器
axios.interceptors.response.use(
    function(response) {
      let url = response.request.responseURL||response.config.url;
      if(url.indexOf("/login")>-1){
        let name = (response.data&&response.data.data&&response.data.data.user.name!=null)?response.data.data.user.name:null
        localStorage.setItem('name',name)
      }
      let token = (response.data&&response.data.data&&response.data.data.token!=null)?response.data.data.token:null;
      if(token){
        localStorage.setItem('token',token)
      }
      return response;
    },
    function(error) {
      // 对响应错误做点什么
      return Promise.reject(error);
    }
);

/**
 * get请求
 * @method get
 * @param {url, params, loading} 请求地址，请求参数，是否需要加载层
 */
let get = function(url, params, loading) {
    return new Promise((resolve, reject) => {
      // {
      //   params: params
      // }
      axios
        .get(url, params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
};

/**
 * post请求
 * @method post
 * @param {url, params} 请求地址，请求参数，是否需要加载层
 */
let post = function(url, data) {
    // let params = qs.stringify(data)
    return new Promise((resolve, reject) => {
      // qs.stringify(data)
      axios
        .post(url, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
};
export  { get, post };
  
