import React from 'react'
import { connect } from 'react-redux'
import rseIcon from '../siteMonitor/assets/RSE.png'
import camIcon from '../siteMonitor/assets/CAM.png'
import vutIcon from './assets/VUT.png'
import vtIcon from './assets/VT.png'
import { wgs84togcj02, gcj02tobd09 } from '../../../api/latLngTransform'

import { Marker, Label } from 'rc-bmap'

const Fragment = React.Fragment


class Childs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            vutPoint: null,
            vtPoint: null
        }
    }

    getMarkerPoint = (lat, lng) => {
        const wgsTogcj = wgs84togcj02(lng, lat);
        return gcj02tobd09(wgsTogcj.lng, wgsTogcj.lat)
    }

    componentWillReceiveProps(nextProps) {
        /* if (this.props.veh && nextProps.veh && this.props.veh.vutData && nextProps.veh.vutData) {
            const latSpeed = (nextProps.veh.vutData.latitude - this.props.veh.vutData.latitude) / 5
            const lngSpeed = (nextProps.veh.vutData.longitude - this.props.veh.vutData.longitude) / 5
            
            this.setState({
                vutPoint: { lat: nextProps.veh.vutData.latitude, lng: nextProps.veh.vutData.longitude }
            })

            setTimeout(() => {
                
            },200)
        } */
    }

    render() {
        const { siteMonitorCamList, siteInfo, markerInfo, monitorRSEdata, veh } = this.props
        const { testMark } = siteInfo;

        return (
            <Fragment>
                {
                    markerInfo && JSON.stringify(markerInfo) !== '{}' && testMark && testMark.map((marker, index) =>
                        <Marker
                            key={marker.id}
                            point={this.getMarkerPoint(marker.latitude, marker.longitude)}
                            offset={{ width: 0, height: -10 }}
                            icon={{ url: markerInfo[marker.markType].imgUrl, size: { width: 20, height: 20 }, opts: { imageSize: { width: 20, height: 20 } } }}
                            label={marker.markType === 1 ? <Label
                                style={{
                                    border: 'none',
                                    background: 'transparent',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '18px',
                                    height: '18px'
                                }}
                                content={marker.param ? JSON.parse(marker.param).speed : 0}
                                offset={{ width: 1, height: 1 }} /> : null}
                            shadow={{ url: markerInfo[marker.markType].imgUrl, size: { width: 0, height: 0 } }}
                        // events={this.getMarkerEvent(marker)}
                        >
                        </Marker>)
                }

                {
                    monitorRSEdata && monitorRSEdata.rseList && monitorRSEdata.rseList.map((rse, index) => {
                        return (
                            <div key={rse.id}>
                                <Marker
                                    shadow={{ url: rseIcon, size: { width: 0, height: 0 } }}
                                    icon={{ url: rseIcon, size: { width: 30, height: 30 }, opts: { imageSize: { width: 30, height: 30 } } }}
                                    point={{ lat: rse.latitude, lng: rse.longitude }}
                                    offset={{ width: 0, height: -12 }}
                                    title={rse.rseId}
                                />

                            </div>
                        )
                    })
                }

                {
                    siteMonitorCamList && siteMonitorCamList.map(cam => <Marker
                        key={cam.id}
                        point={new window.BMap.Point(cam.longitude, cam.latitude)}
                        icon={{ url: camIcon, size: { width: 30, height: 30 }, opts: { imageSize: { width: 30, height: 30 } } }}
                        shadow={{ url: camIcon, size: { width: 0, height: 0 } }}
                    />)
                }

                {
                    veh && veh.vutData && <Marker
                        rotation={veh.vutData.heading}
                        point={new window.BMap.Point(veh.vutData.longitude, veh.vutData.latitude)}
                        icon={{ url: vutIcon, size: { width: 10.8, height: 20.4 }, opts: { imageSize: { width: 10.8, height: 20.4 } } }}
                        shadow={{ url: vutIcon, size: { width: 0, height: 0 } }}
                    >
                    </Marker>

                }

                {
                    veh && veh.vtData && <Marker
                        rotation={veh.vtData.heading}
                        point={new window.BMap.Point(veh.vtData.longitude, veh.vtData.latitude)}
                        icon={{ url: vtIcon, size: { width: 10.8, height: 20.4 }, opts: { imageSize: { width: 10.8, height: 20.4 } } }}
                        shadow={{ url: vtIcon, size: { width: 0, height: 0 } }}
                    >
                    </Marker>

                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { siteMonitorCamList, realTimeData, monitorRSEdata } = state.realTime;
    const { siteInfo, markerInfo } = state.siteConfig;
    const { veh } = realTimeData;

    return {
        siteMonitorCamList,
        realTimeData,
        siteInfo,
        markerInfo,
        monitorRSEdata,
        veh,
    }
}

export default connect(mapStateToProps)(Childs)